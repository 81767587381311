import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from '@bwoty-web/ui-kit';
import { debounce } from '@bwoty-web/ui-kit/utils';
import { useState, useEffect } from 'react';
import { useIsGlobetrotter, useIsMobile } from '@bwoty-web/ui-kit/hooks';
import {
  UI_CHEVRON_DOWN,
  UI_CHEVRON_RIGHT,
  UI_CHEVRON_UP,
} from '@bwoty-web/ui-kit/icons';

import './latestSearches.scss';

const LatestSearches = ({ labels, searches, dateSettings }) => {
  const isMobile = useIsMobile();
  const isGlobetrotter = useIsGlobetrotter();
  const [showAllSearches, setShowAllSearches] = useState(!isMobile);

  useEffect(() => {
    const handleResize = () => {
      const latestSearchesContainerHeight = document.getElementsByClassName('bs-latest-searches')[0]?.clientHeight;
      document.getElementsByClassName('bookingstart--isGlobeNLatestSearches')[0]?.style.setProperty('--latestSearchesContainerHeight', `${latestSearchesContainerHeight}px`);
    };
    if (isGlobetrotter) {
      setTimeout(() => {
        handleResize();
      }, 100);
      window.addEventListener('resize', debounce(handleResize, 100));
      return () => {
        window.removeEventListener('resize', debounce(handleResize, 100));
      };
    }
  }, []);

  const capitalize = (value) => value.charAt(0).toUpperCase() + value.slice(1);

  const renderButtonOrIcon = () => {
    if (isGlobetrotter && !isMobile) return null
    if (isGlobetrotter && isMobile) {
      return (
        <Icon
          alt={showAllSearches ? 'Visa mindre' : 'Visa fler'}
          className="bs-latest-searches__expand-icon"
          onClick={() => setShowAllSearches((prevState) => !prevState)}
          path={showAllSearches ? UI_CHEVRON_UP : UI_CHEVRON_DOWN}
          size="xxxs"
        />
      );
    }
    return !showAllSearches ? (
      <button
        type="button"
        className="bs-latest-searches__expand-button"
        onClick={() => setShowAllSearches(true)}
      >
        {labels.showMoreLabel}
      </button>
    ) : null;
  };

  return (
    <div className="bs-latest-searches">
      <div className="bs-latest-searches__heading">
        {labels.latestSearchesLabel}
        {searches.length > 1 && renderButtonOrIcon()}
      </div>
      <div className="bs-latest-searches__items">
        {searches.map((search, index) => {
          if (!showAllSearches && index > 0) {
            return null;
          }

          const {
            destinationName,
            destinationParentName,
            startDate,
            endDate,
            departureMonths,
          } = search;
          const hasParent =
            destinationParentName && destinationParentName !== '';

          const destination = `${destinationName}${
            hasParent !== '' ? `, ${destinationParentName}` : ''
          }`;

          let duration;
          if (departureMonths != null && departureMonths.length > 0) {
            duration = departureMonths
              .map((date) => {
                const dateMoment = moment(date, 'YYYYMM');
                const month = dateSettings.months[dateMoment.month()];
                return `${capitalize(month)} ${dateMoment.year()}`;
              })
              .join(', ');
          } else {
            duration = startDate + (endDate !== '' ? ` - ${endDate}` : '');
          }

          return (
            <a
              className="bs-latest-searches__item-link"
              href={search.searchResultUrl}
              key={search.searchResultUrl}
            >
              <div className="bs-latest-searches__destination">
                {destination}
              </div>
              <div className="bs-latest-searches__duration">{duration}</div>
              <Icon
                className="bs-latest-searches__item-arrow"
                path={UI_CHEVRON_RIGHT}
                size="xs"
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

LatestSearches.propTypes = {
  labels: PropTypes.shape({
    latestSearchesLabel: PropTypes.string,
    showMoreLabel: PropTypes.string,
  }),
  searches: PropTypes.arrayOf(
    PropTypes.shape({
      searchResultUrl: PropTypes.string,
      destinationName: PropTypes.string,
      destinationParentName: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  ),
  dateSettings: PropTypes.shape({
    months: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

LatestSearches.defaultProps = {
  labels: {},
  searches: [],
};

export default LatestSearches;
