import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import DestinationSearch from './DestinationSearch';

const NUMBER_OF_COLUMNS = 3;

const Destination = ({ destination, setDestination, selectedDestination }) => {
  const destinationClasses = ClassNames({
    'destination-select__item': true,
    'destination-select__item--selected': destination.itemId == selectedDestination.itemId,
  });

  return (
    <button
      className={destinationClasses}
      onClick={() => setDestination(destination)}
      type="button"
    >
      {destination.name}
    </button>
  );
};

const Column = ({ destinations, selectedDestination, setDestination }) => {
  return (
    <div className="destination-select__column">
      {destinations.map((destination, index) => {
        return <Destination key={index} destination={destination} selectedDestination={selectedDestination} setDestination={setDestination} />;
      })}
    </div>
  );
};

const DestinationSimpleSelect = (props) => {
  const { destinationList, selectedDestination, searchDestinations, searchResult, labels, setDestination, setDestinationBySearch } = props;

  const minDestinationsPerCol = Math.floor(destinationList.length / NUMBER_OF_COLUMNS);
  let rest = destinationList.length - minDestinationsPerCol * NUMBER_OF_COLUMNS;
  const columns = [];
  let counter = 0;

  for (let i = 0; i < NUMBER_OF_COLUMNS; i++) {
    let numberOfItems = minDestinationsPerCol;
    const destinations = destinationList.slice();

    if (rest > 0) {
      numberOfItems++;
      rest--;
    }

    const destinationsInColumn = destinations.slice(counter, counter + numberOfItems);
    columns.push(destinationsInColumn);
    counter += numberOfItems;
  }

  return (
    <div className="destination-select">
      {searchDestinations ? (
        <DestinationSearch
          onTextSearch={searchTerm => searchDestinations(searchTerm)}
          searchResult={searchResult}
          selectDestination={destination => setDestinationBySearch(destination)}
          labels={labels}
        />
      ) : null}

      {columns.map((destinationsInColumn, index) => {
        return <Column key={index} destinations={destinationsInColumn} selectedDestination={selectedDestination} setDestination={setDestination} />;
      })}
    </div>
  );
};

DestinationSimpleSelect.propTypes = {
  destinationList: PropTypes.array.isRequired,
  selectedDestination: PropTypes.object.isRequired,
  setDestination: PropTypes.func.isRequired,
  setDestinationBySearch: PropTypes.func,
  searchDestinations: PropTypes.func,
  searchResult: PropTypes.object,
  labels: PropTypes.object,
};

export default DestinationSimpleSelect;
