import { ActionTypes } from '../../constants/actionTypes/bookingstart';
import { getBsInitData } from '../../../services/api';
import { fetchAttributeCollection } from '../../../services/attributeCollection';

const getProductIndex = (productName) => {
  switch (productName) {
    case 'package':
      return 0;
    case 'city':
      return 1;
    case 'cruise':
      return 2;
    case 'flight':
      return 3;
    default:
      return 0;
  }
};

export const changeSelectedProduct = (index) => {
  return { type: ActionTypes.CHANGE_SELECTED_PRODUCT, index };
};

export const init = (siteSettings, selectedProduct) => {
  const { siteId, alias, apiUrl, cookieId, baseUrl } = siteSettings;
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.INIT,
      selectedProduct,
      siteSettings,
    });

    const bsInitData = await getBsInitData(siteId, apiUrl, cookieId, baseUrl);
    const labels = await fetchAttributeCollection(baseUrl, siteId, alias);
    // eslint-disable-next-line no-restricted-globals
    const productIndex = (selectedProduct === null || isNaN(selectedProduct))
      ? getProductIndex(bsInitData.selectedSectionFromCookies)
      : selectedProduct;

    dispatch({
      type: ActionTypes.INIT_SUCCESS,
      labels,
      selectedProduct: productIndex,
    });
  };
};

export const searchInit = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SEARCH_INIT,
    });
  };
};
