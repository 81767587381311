import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Globals } from '@bwoty-web/ui-kit';

import Bookingstart from './containers/Bookingstart';
import bookingstartStore from './redux/stores/bookingstart';

const initialState = window.__INITIAL_STATE_BOOKINGSTART__;
delete window.__INITIAL_STATE_BOOKINGSTART__;

if (initialState) {
  const { siteId, legacyMode } = initialState.siteSettings || {};

  render(
    <Provider store={bookingstartStore}>
      <Globals siteId={siteId} legacyMode={legacyMode}>
        <Bookingstart
          siteSettings={initialState.siteSettings}
          preSelectedSettings={initialState.preSelectedSettings}
        />
      </Globals>
    </Provider>,
    document.getElementById('bookingstart-app-root'),
  );
}
