const TABLE_WIDTH = 768;

export const isSmallScreen = () => {
  return window && window.innerWidth < TABLE_WIDTH;
};

export const isLargeScreen = () => {
  return window && window.innerWidth >= TABLE_WIDTH;
};

export const isTouch = () => {
  return window && window.Modernizr && window.Modernizr.touch;
};
