import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import ClassNames from 'classnames';
import { Button } from '@bwoty-web/ui-kit';

import * as PackageActions from '../redux/actions/bookingstart/package';
import * as BookingstartActions from '../redux/actions/bookingstart/bookingstart';
import * as packageService from '../services/package';
import { validRoomDistribution } from '../utils/pax/validation';
import { ScrollBsIntoViewIfNeeded, ScrollElementToBottomOfScreen } from '../utils/scroll';
import { getPriceCalendarDataKey } from '../utils/actionHelper';
import SelectOverlay from '../components/selectOverlay/SelectOverlay';
import DepartureField from '../components/departure/DepartureField';
import DepartureSelect from '../components/departure/DepartureSelect';
import DestinationField from '../components/destination/DestinationField';
import DestinationSelect from '../components/destination/DestinationSelect';
import DurationField from '../components/duration/DurationField';
import DurationSelect from '../components/duration/DurationSelect';
import PaxField from '../components/pax/PaxField';
import PaxSelect from '../components/pax/PaxSelect';
import DatePicker from '../components/date/DatePicker';
import DateField from '../components/date/DateField';
import { maxNumberOfPax } from '../constants/pax';
import bookingTypes from '../constants/bookingTypes';

const showMonthCheckbox = (bookingType) => {
  return bookingType === bookingTypes.charter;
};

class PackageSection extends Component {
  constructor(props) {
    super();

    this.state = {
      showDepartureLayer: false,
      showDurationLayer: false,
      showDestinationLayer: false,
      showPaxLayer: false,
      showDurationField: this.showDurationField(props.durationList),
      dateSelectionInProgress: false,
      departureDateDuringSelection: null,
      returnDateDuringSelection: null,
      paxIsValid: true,
      changeAllPaxInSameRoom: null,
    };

    this.independentRange = {
      min: moment().add(props.siteSettings.datePicker.dpBookDaysAhead, 'days'),
      max: moment().add(props.siteSettings.datePicker.dpMaxDaysToReturn, 'days'),
    };

    this.charterRange = {
      min: moment(),
      max: moment().add(19, 'month').endOf('month'),
    };

    this.setDate = this.setDate.bind(this);
    this.toggleDateLayer = this.toggleDateLayer.bind(this);
    this.setDateSelectionInProgress = this.setDateSelectionInProgress.bind(this);
    this.setDepartureDateDuringSelection = this.setDepartureDateDuringSelection.bind(this);
    this.setReturnDateDuringSelection = this.setReturnDateDuringSelection.bind(this);
    this.setDepartureMonths = this.setDepartureMonths.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.setAllPaxInSameRoomFunc = this.setAllPaxInSameRoomFunc.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ showDurationField: this.showDurationField(props.durationList) });
  }

  componentDidUpdate(prevProps, prevState) {
    const { showPaxLayer } = this.state;
    if (showPaxLayer !== prevState.showPaxLayer) {
      if (showPaxLayer) {
        document.addEventListener('keyup', this.onKeyDown);
      } else {
        document.removeEventListener('keyup', this.onKeyDown);
      }
    }
  }

  showDurationField(durationList) {
    if (!durationList) {
      return true;
    }

    const numberOfDurationTypes = durationList.length;
    return numberOfDurationTypes > 1 || (numberOfDurationTypes === 1 && !durationList[0].flexibleDuration);
  }

  onKeyDown(event) {
    if (event.keyCode === 13) {
      if (document.activeElement.id === 'same-room') {
        const { changeAllPaxInSameRoom } = this.state;
        changeAllPaxInSameRoom();
      }
    }
  }

  setAllPaxInSameRoomFunc(setAllPaxInSameRoomFunc) {
    this.setState({
      changeAllPaxInSameRoom: setAllPaxInSameRoomFunc,
    });
  }

  getDateRange(bookingType) {
    return bookingType == 'independent' ? this.independentRange : this.charterRange;
  }

  setDepartureAirport(airport) {
    this.props.dispatch(PackageActions.changeDepartureAirport(airport));
    this.toggleDepartureLayer();
  }

  setDestination(destination) {
    this.props.dispatch(PackageActions.changeDestination(destination));
    this.toggleDestinationLayer();
    ScrollBsIntoViewIfNeeded();
  }

  setDuration(duration) {
    const { dispatch } = this.props;
    dispatch(PackageActions.changeDuration(duration));
    this.toggleDurationLayer();
  }

  setDate(departureDate, returnDate) {
    if (returnDate != null) {
      this.props.dispatch(PackageActions.changeDates(departureDate, returnDate));
    } else {
      this.props.dispatch(PackageActions.changeDepartureDate(departureDate));
    }
  }

  setRoomDistribution(roomDistribution, selectedNumberOfRooms, isSameRoom) {
    this.props.dispatch(PackageActions.changeRoomDistribution(roomDistribution, selectedNumberOfRooms, isSameRoom));
    this.togglePaxLayer();
  }

  setDepartureMonths(months) {
    this.props.dispatch(PackageActions.changeDepartureMonths(months, this.props.departureDates));
  }

  searchDestinations(searchTerm) {
    const { selectedDepartureAirport } = this.props;
    this.props.dispatch(PackageActions.searchDestinations(selectedDepartureAirport.itemId, searchTerm));
  }

  navigateToResultPage() {
    this.props.dispatch(BookingstartActions.searchInit());

    packageService.getSearchUrl(this.props).then((url) => {
      window.location.href = url;
    });
  }

  searchPackagePaxControl() {
    const { roomDistribution } = this.props;
    const { showPaxLayer } = this.state;

    if (showPaxLayer) {
      // Wait to the pax layer is closed
      setTimeout(() => {
        if (validRoomDistribution(roomDistribution)) {
          this.navigateToResultPage();
        } else {
          this.togglePaxLayer(false);
        }
      }, 10);
    } else if (validRoomDistribution(roomDistribution)) {
      this.navigateToResultPage();
    } else {
      this.togglePaxLayer(false);
    }
  }

  searchPackage() {
    const { showPriceCalendar, selectedOffer, selectedDepartureDate } = this.props;

    if (showPriceCalendar && !selectedOffer) {
      this.props.dispatch(PackageActions.changeDepartureDate(selectedDepartureDate));
       setTimeout(() => {
         this.searchPackagePaxControl();
     }, 10);
    }
    else {
      this.searchPackagePaxControl();
    }
  }

  isSelectOverlayOpen() {
    return this.state.showDepartureLayer || this.state.showDurationLayer || this.props.showDateLayer || this.state.showDestinationLayer || this.state.showPaxLayer;
  }

  toggleDepartureLayer() {
    this.setState({ showDepartureLayer: !this.state.showDepartureLayer });
  }

  toggleDestinationLayer() {
    this.setState({ showDestinationLayer: !this.state.showDestinationLayer });
  }

  toggleDurationLayer() {
    this.setState({ showDurationLayer: !this.state.showDurationLayer });
  }

  toggleDateLayer() {
    if (this.props.showDateLayer && this.state.returnDateDuringSelection == null) {
      this.setDateSelectionInProgress(false);
      this.setDepartureDateDuringSelection(null);
    }

    const { dispatch, showDateLayer } = this.props;
    dispatch(PackageActions.setShowDateLayer(!showDateLayer))
  }

  togglePaxLayer(paxIsValid = true) {
    this.setState({
      showPaxLayer: !this.state.showPaxLayer,
      paxIsValid,
    });
    if (window.innerWidth < 768) {
      ScrollElementToBottomOfScreen(document.getElementsByClassName('bs-form__search-button')[0]);
    }
  }

  setDepartureDateDuringSelection(departureDate) {
    this.setState({ departureDateDuringSelection: departureDate });
  }

  setReturnDateDuringSelection(returnDate) {
    this.setState({ returnDateDuringSelection: returnDate });
  }

  setDateSelectionInProgress(dateSelectionInProgress) {
    this.setState({ dateSelectionInProgress });
  }

  setShowPriceCalendar(show) {
    const { dispatch } = this.props;
    dispatch(PackageActions.setShowPriceCalendar(show));
  }

  changeDurationForPriceCalendar(priceCalendarDuration) {
    const { dispatch, durationList, selectedDuration } = this.props;

    const priceCalendarDurationIncludedInDurations = durationList
      .find(duration => !duration.flexibleDuration && duration.defaultDuration === priceCalendarDuration.defaultDuration);
    const durationToSelect = priceCalendarDurationIncludedInDurations || priceCalendarDuration;
    
    dispatch(PackageActions.setPreviousDuration(selectedDuration));
    dispatch(PackageActions.changeDuration(durationToSelect, false));
  }

  getCurrentPriceCalendarData() {
    const {
      selectedDepartureAirport,
      selectedDestination,
      selectedDuration,
      priceCalendarData,
      showPriceCalendar,
      showDirectFlights,
    } = this.props;

    const shouldShowPrices = showPriceCalendar && selectedDuration.hasCalendar;
    if (!shouldShowPrices) {
      return [];
    }
    const key = getPriceCalendarDataKey(selectedDepartureAirport.itemId, selectedDestination.itemId, 0, selectedDuration, showDirectFlights);
    return priceCalendarData[key];
  }

  render() {
    const {
      dispatch,
      labels,
      siteSettings,
      isLoading,
      selectedDepartureAirport,
      departureAirportList,
      selectedDestination,
      searchResultsDestination,
      countryList,
      durationList,
      selectedDuration,
      roomDistribution,
      selectedNumberOfRooms,
      isEveryoneInSameRoomChecked,
      selectedMonths,
      selectedDepartureDate,
      selectedReturnDate,
      departureDates,
      showPriceCalendar,
      showDirectFlights,
      priceCalendarDurationOptions,
      previousDuration,
      showCabinClasses,
      selectedCabinClass,
      showDirectOnly,
      searchDirectOnly,
      showLuggageIncluded,
      searchLuggageIncluded,
      showDateLayer,
    } = this.props;

    const {
      showDepartureLayer,
      showDestinationLayer,
      showDurationLayer,
      showDurationField,
      showPaxLayer,
      paxIsValid,
      dateSelectionInProgress,
      departureDateDuringSelection,
      returnDateDuringSelection,
    } = this.state;

    const {
      departureLayerHeading,
      destinationLayerHeading,
      durationLayerHeading,
      searchButtonText,
    } = labels;

    const packageSectionClasses = ClassNames({
      'bs-form': true,
      'bs-form--package': true,
      'bs-form--no-duration-field': !showDurationField,
    });

    return (
      <div className={packageSectionClasses}>
        <DepartureField
          labels={labels}
          isLoading={isLoading}
          isActive={showDepartureLayer}
          isOtherFieldActive={!showDepartureLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDepartureLayer()}
          selectedAirport={selectedDepartureAirport}
        >
          {!isLoading && (
            <SelectOverlay
              heading={departureLayerHeading}
              close={() => this.toggleDepartureLayer()}
            >
              <DepartureSelect
                airportList={departureAirportList}
                selectedAirport={selectedDepartureAirport}
                changeAirport={airport => this.setDepartureAirport(airport)}
              />
            </SelectOverlay>
          )}
        </DepartureField>

        <DestinationField
          labels={labels}
          isLoading={isLoading}
          isActive={showDestinationLayer}
          isOtherFieldActive={!showDestinationLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDestinationLayer()}
          selectedDestination={selectedDestination}
          countryList={countryList}
        >
          {!isLoading && (
            <SelectOverlay
              heading={destinationLayerHeading}
              close={() => this.toggleDestinationLayer()}
            >
              <DestinationSelect
                countries={countryList}
                selectedDestination={selectedDestination}
                setDestination={destination => this.setDestination(destination)}
                searchDestinations={searchString => this.searchDestinations(searchString)}
                searchResult={searchResultsDestination}
                labels={labels}
              />
            </SelectOverlay>
          )}
        </DestinationField>

        <DurationField
          labels={labels}
          isLoading={isLoading}
          isActive={showDurationLayer}
          isOtherFieldActive={!showDurationLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDurationLayer()}
          selectedDuration={selectedDuration}
          showDurationField={showDurationField}
        >
          {!isLoading && (
            <SelectOverlay
              heading={durationLayerHeading}
              close={() => this.toggleDurationLayer()}
            >
              <DurationSelect
                durationList={durationList}
                selectedDuration={selectedDuration}
                changeDuration={duration => this.setDuration(duration)}
                labels={labels}
              />
            </SelectOverlay>
          )}
        </DurationField>

        <DateField
          dateSettings={siteSettings.datePicker}
          labels={labels}
          isLoading={isLoading}
          isActive={showDateLayer}
          isOtherFieldActive={!showDateLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDateLayer()}
          selectedDepartureDate={selectedDepartureDate}
          selectedReturnDate={selectedReturnDate}
          selectedDuration={selectedDuration}
          datePickerIsActive={showDateLayer}
          selectedMonths={selectedMonths}
          dateSelectionInProgress={dateSelectionInProgress}
          setDateSelectionInProgress={inProgress => this.setDateSelectionInProgress(inProgress)}
          departureDateDuringSelection={departureDateDuringSelection}
          returnDateDuringSelection={returnDateDuringSelection}
          isHotelOnly={false}
        >
          {!isLoading && showDateLayer && (
            <DatePicker
              dateSettings={siteSettings.datePicker}
              selectedDepartureDate={selectedDepartureDate}
              selectedReturnDate={selectedReturnDate}
              range={this.getDateRange(selectedDuration.bookingType)}
              changeDate={this.setDate}
              selectableDepartureDates={departureDates}
              selectedDuration={selectedDuration}
              close={this.toggleDateLayer}
              isHidden={!showDateLayer}
              labels={labels}
              setDateSelectionInProgress={this.setDateSelectionInProgress}
              setDepartureDateDuringSelection={this.setDepartureDateDuringSelection}
              setReturnDateDuringSelection={this.setReturnDateDuringSelection}
              monthSelector={{ months: selectedMonths, set: this.setDepartureMonths, show: showMonthCheckbox(selectedDuration.bookingType) }}
              isHotelOnly={false}
              showPriceCalendar={showPriceCalendar}
              showDirectFlights={showDirectFlights}
              priceCalendarDurationOptions={priceCalendarDurationOptions}
              changeDurationForPriceCalendar={duration => this.changeDurationForPriceCalendar(duration)}
              setShowPriceCalendar={(show) => this.setShowPriceCalendar(show)}
              setShowDirectFlights={show => dispatch(PackageActions.setShowDirectFlights(show))}
              priceCalendarData={this.getCurrentPriceCalendarData()}
              previousDuration={previousDuration}
              changeDuration={duration => dispatch(PackageActions.changeDuration(duration))}
              siteId={siteSettings.siteId}
              showDirectOnly={showDirectOnly}
              searchDirectOnly={searchDirectOnly}
              setSearchDirectOnly={toggle => dispatch(PackageActions.setSearchDirectOnly(toggle))}
              showLuggageIncluded={showLuggageIncluded}
              searchLuggageIncluded={searchLuggageIncluded}
              setSearchLuggageIncluded={toggle => dispatch(PackageActions.setSearchLuggageIncluded(toggle))}
              selectedCabinClass={selectedCabinClass}
            />
          )}
        </DateField>

        <PaxField
          labels={labels}
          isLoading={isLoading}
          isActive={showPaxLayer}
          isOtherFieldActive={!showPaxLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.togglePaxLayer()}
          roomDistribution={roomDistribution}
          selectedCabinClass={selectedCabinClass}
        >
          {!isLoading && (
            <PaxSelect
              labels={labels}
              numberOfRooms={selectedNumberOfRooms}
              roomDistribution={roomDistribution}
              changeRoomDistribution={(rooms, numberOfRooms, isSameRoom) => {
                this.setRoomDistribution(rooms, numberOfRooms, isSameRoom);
              }}
              showManyPaxLink={selectedDuration.bookingType === bookingTypes.charter}
              multipleRooms={selectedDuration.bookingType === bookingTypes.dp}
              preferAllPaxInSameRoom={isEveryoneInSameRoomChecked}
              maxNumberOfPax={maxNumberOfPax.charter}
              activeValidation={!paxIsValid}
              close={() => this.togglePaxLayer()}
              setAllPaxInSameRoomFunc={(setAllPaxInSameRoomFunc) => {
                this.setAllPaxInSameRoomFunc(setAllPaxInSameRoomFunc);
              }}
              showCabinClasses={showCabinClasses}
              selectedCabinClass={selectedCabinClass}
              setCabinClass={(cabinclass) => 
                dispatch(PackageActions.setCabinClass(cabinclass))
              }
              isDP={selectedDuration.bookingType === bookingTypes.dp}
            />
          )}
        </PaxField>

        <div className="bs-form__button-container">
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={() => this.searchPackage()}
            rel="nofollow"
            className="bs-form__search-button"
          >
            {searchButtonText}
          </Button>
        </div>
      </div>
    );
  }
}

PackageSection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  durationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  roomDistribution: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDuration: PropTypes.shape({
    flexibleDuration: PropTypes.bool.isRequired,
    bookingType: PropTypes.string.isRequired,
    defaultDuration: PropTypes.number.isRequired,
    hasCalendar: PropTypes.bool.isRequired,
  }).isRequired,
  selectedDepartureDate: PropTypes.string.isRequired,
  selectedDepartureAirport: PropTypes.shape().isRequired,
  selectedDestination: PropTypes.shape().isRequired,
  labels: PropTypes.shape({
    departureLayerHeading: PropTypes.string,
    destinationLayerHeading: PropTypes.string,
    durationLayerHeading: PropTypes.string,
    searchButtonText: PropTypes.string,
  }).isRequired,
  siteSettings: PropTypes.shape({
    datePicker: PropTypes.shape({
      dpBookDaysAhead: PropTypes.number,
      dpMaxDaysToReturn: PropTypes.number,
    }),
  }).isRequired,
  showPriceCalendar: PropTypes.bool.isRequired,
  showDirectFlights: PropTypes.bool.isRequired,
  priceCalendarDurationOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  priceCalendarData: PropTypes.shape().isRequired,
  previousDuration: PropTypes.shape(),
  showCabinClasses: PropTypes.arrayOf(PropTypes.string),
  selectedCabinClass: PropTypes.string,
  showDirectOnly: PropTypes.bool,
  searchDirectOnly: PropTypes.bool,
  showLuggageIncluded: PropTypes.bool,
  searchLuggageIncluded: PropTypes.bool,
  showDateLayer: PropTypes.bool,
};

PackageSection.defaultProps = {
  previousDuration: null,
  showCabinClasses: [],
  selectedCabinClass: null,
  showDirectOnly: false,
  searchDirectOnly: false,
  showLuggageIncluded: false,
  searchLuggageIncluded: false,
  showDateLayer: false,
};

const select = (state) => {
  return {
    isLoading: state.package.loading || !state.bookingstart.packageSectionInitialized,
    siteSettings: state.bookingstart.siteSettings,
    departureAirportList: state.package.departureAirportList,
    selectedDepartureAirport: state.package.selectedDepartureAirport,
    countryList: state.package.countryList,
    selectedDestination: state.package.selectedDestination,
    durationList: state.package.durationList,
    selectedDuration: state.package.selectedDuration,
    nOfRoomsList: state.package.nOfRoomsList,
    selectedNumberOfRooms: state.package.selectedNumberOfRooms,
    selectedDepartureDate: state.package.selectedDepartureDate,
    selectedReturnDate: state.package.selectedReturnDate,
    isEveryoneInSameRoomChecked: state.package.isEveryoneInSameRoomChecked,
    searchResultsDestination: state.package.searchResultsDestination,
    departureDates: state.package.departureDates,
    roomDistribution: state.package.rooms,
    labels: state.bookingstart.labels,
    selectedMonths: state.package.departureMonths,
    showPriceCalendar: state.package.showPriceCalendar,
    showDirectFlights: state.package.showDirectFlights,
    priceCalendarDurationOptions: state.package.priceCalendarDurationOptions,
    priceCalendarData: state.package.priceCalendarData,
    previousDuration: state.package.previousDuration,
    selectedOffer: state.package.selectedOffer,
    showDirectOnly: state.package.showDirectOnly,
    searchDirectOnly: state.package.searchDirectOnly,
    showLuggageIncluded: state.package.showLuggageIncluded,
    searchLuggageIncluded: state.package.searchLuggageIncluded,
    showCabinClasses: state.package.showCabinClasses,
    selectedCabinClass: state.package.selectedCabinClass,
    showDateLayer: state.package.showDateLayer,
  };
};

export default connect(select)(PackageSection);
