import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/root';

const finalCreateStore = compose(
  applyMiddleware(thunkMiddleware),
)(createStore);

const bsAppStore = finalCreateStore(rootReducer);

export default bsAppStore;
