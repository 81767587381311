/* eslint-disable react/no-deprecated */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
import { Component, memo, useCallback, useMemo } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import moment from 'moment';
import ClassNames from 'classnames';
import {
  Button,
  Checkbox,
  DatePicker as BaseDatePicker,
  MonthHeader,
} from '@bwoty-web/ui-kit';
import { MISC_DIRECT_FLIGHT_ARROW } from '@bwoty-web/ui-kit/icons';

import { startingHotjarListener } from '../../utils/log/gtm';
import SelectOverlay from '../selectOverlay/SelectOverlay';
import DatePickerHelpMessages from './DatePickerHelpMessages';
import PriceCalendarControls from '../priceCalendar/PriceCalendarControls';
import { isLargeScreen, isSmallScreen } from '../../utils/device';
import bookingTypes from '../../constants/bookingTypes';
import './datePicker.scss';

const sortMonthsByDate = (previous, current) => {
  if (current.isBefore(previous)) {
    return 1;
  }

  return -1;
};

const monthPropsIsEqual = (array, previousArray) => {
  return (
    array.every((item) =>
      previousArray.find((element) => element.isSame(item, 'month')),
    ) && array.length === previousArray.length
  );
};

// eslint-disable-next-line react/display-name
export const MonthFooter = memo(
  ({
    onSelect,
    label = 'Välj alla datum i',
    selectedMonths = [],
    monthName,
    monthDateString,
  }) => {
    const uniqueId = `bs-dp-mf-${monthDateString}`;
    const date = moment(`${monthDateString}-01`);

    const checked =
      selectedMonths.filter((selectedMonth) =>
        selectedMonth.isSame(date, 'month'),
      ).length > 0;

    const onChange = useCallback(() => {
      onSelect(date);
    }, [date, onSelect]);

    const mLabel = monthName.toLocaleLowerCase();

    return (
      <Checkbox
        checked={checked}
        uniqueId={uniqueId}
        onChange={onChange}
        data-test-id="all-dates"
      >
        {label} {mLabel}
      </Checkbox>
    );
  },
);

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDepartureDate: moment(props.selectedDepartureDate),
      selectedReturnDate: moment(props.selectedReturnDate),
      dateSelectionInProgress: false,
      selectedMonths: props.monthSelector.months,
    };

    this.selectDate = this.selectDate.bind(this);
    this.toggleMonth = this.toggleMonth.bind(this);

    this.isHotjarListenerStarted = false;
  }

  componentWillMount() {
    this.clearDatesDuringSelection();
  }

  componentWillReceiveProps(nextProps) {
    const {
      isHidden,
      selectedDepartureDate,
      selectedReturnDate,
      selectedDuration,
      priceCalendarDurationOptions,
    } = this.props;

    if (nextProps.isHidden !== isHidden) {
      this.clearDatesDuringSelection();
    }

    if (
      nextProps.selectedDepartureDate !== selectedDepartureDate ||
      nextProps.selectedReturnDate !== selectedReturnDate
    ) {
      this.setState({
        selectedDepartureDate: moment(selectedDepartureDate),
        selectedReturnDate: moment(selectedReturnDate),
        dateSelectionInProgress: false,
      });
    }

    const { flexibleDuration, hasCalendar } = selectedDuration;
    if (
      !this.isHotjarListenerStarted &&
      (hasCalendar ||
        (priceCalendarDurationOptions?.length && flexibleDuration))
    ) {
      this.isHotjarListenerStarted = true;
      startingHotjarListener();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedReturnDate,
      selectedDepartureDate,
      isHidden,
      monthSelector,
    } = this.props;

    if (
      !monthPropsIsEqual(monthSelector.months, prevProps.monthSelector.months)
    ) {
      this.setState({ selectedMonths: monthSelector.months });
    }

    if (prevProps.isHidden !== isHidden) {
      this.setState({
        selectedDepartureDate: moment(selectedDepartureDate),
        selectedReturnDate: moment(selectedReturnDate),
        dateSelectionInProgress: false,
      });
    }
  }

  clearDatesDuringSelection() {
    const { setDepartureDateDuringSelection, setReturnDateDuringSelection } =
      this.props;
    // Clear datesDuringSelection values for new date selection.
    if (setDepartureDateDuringSelection && setReturnDateDuringSelection) {
      setDepartureDateDuringSelection(null);
      setReturnDateDuringSelection(null);
    }
  }

  selectDate(newDate) {
    const date = moment(newDate);

    const {
      selectedDuration,
      setDateSelectionInProgress,
      setDepartureDateDuringSelection,
      setReturnDateDuringSelection,
    } = this.props;
    const { dateSelectionInProgress, selectedDepartureDate } = this.state;

    this.setState({
      selectedMonths: [],
    });

    if (isLargeScreen()) {
      if (!selectedDuration.flexibleDuration) {
        this.confirmDate(date, null, []);
      } else if (dateSelectionInProgress) {
        this.confirmDate(selectedDepartureDate, date, []);
      }
    }

    if (!dateSelectionInProgress) {
      if (isSmallScreen() && !selectedDuration.flexibleDuration) {
        this.setState({
          selectedMonths: [],
          selectedDepartureDate: date,
        });
      }

      if (selectedDuration.flexibleDuration) {
        this.setState({
          selectedDepartureDate: date,
          dateSelectionInProgress: true,
        });
        setDateSelectionInProgress(true);
        setDepartureDateDuringSelection(date);
      }
    } else {
      this.setState({
        selectedReturnDate: date,
        dateSelectionInProgress: false,
      });
      setReturnDateDuringSelection(date);
    }
  }

  toggleMonth(month) {
    const { close, monthSelector } = this.props;
    const { selectedMonths } = this.state;

    const alreadyChecked =
      selectedMonths.filter((selectedMonth) =>
        selectedMonth.isSame(month, 'month'),
      ).length > 0;

    const monthList = !alreadyChecked
      ? [...selectedMonths, month]
      : selectedMonths.filter(
          (selectedMonth) => !selectedMonth.isSame(month, 'month'),
        );

    monthList.sort(sortMonthsByDate);

    this.setState({
      selectedMonths: monthList,
    });

    if (isLargeScreen()) {
      monthSelector.set(monthList);
      close();
    }
  }

  confirmFlexibleDurationDates(
    newSelectedDepartureDate,
    newSelectedReturnDate,
  ) {
    const {
      changeDate,
      setDateSelectionInProgress,
      dateSettings,
      selectedDepartureDate,
      selectedReturnDate,
    } = this.props;

    const depDateIsSame = moment(newSelectedDepartureDate).isSame(
      moment(selectedDepartureDate),
      'days',
    );
    const retDateIsSame = moment(newSelectedReturnDate).isSame(
      moment(selectedReturnDate),
      'days',
    );
    if (depDateIsSame && retDateIsSame) {
      setDateSelectionInProgress(false);
    }

    changeDate(
      newSelectedDepartureDate.format(dateSettings.systemDateFormat),
      newSelectedReturnDate.format(dateSettings.systemDateFormat),
    );
  }

  confirmFixedDurationDates(selectedDepartureDate, selectedMonths) {
    const { changeDate, monthSelector, dateSettings } = this.props;

    monthSelector.set(selectedMonths);
    changeDate(
      selectedDepartureDate.format(dateSettings.systemDateFormat),
      null,
    );
  }

  confirmDate(selectedDepartureDate, selectedReturnDate, selectedMonths) {
    const { selectedDuration, close } = this.props;
    close();

    if (selectedDuration.flexibleDuration) {
      this.confirmFlexibleDurationDates(
        selectedDepartureDate,
        selectedReturnDate,
      );
    } else {
      this.confirmFixedDurationDates(selectedDepartureDate, selectedMonths);
    }
  }

  close() {
    const { close, setDateSelectionInProgress, monthSelector } = this.props;

    if (setDateSelectionInProgress) {
      setDateSelectionInProgress(false);
    }

    this.setState({
      selectedMonths: monthSelector.months,
    });

    close();
  }

  priceCalendarAvailable = () => {
    const {
      selectedCabinClass,
      priceCalendarDurationOptions,
      selectedDuration,
      labels,
    } = this.props;

    return (
      selectedDuration.bookingType === 'independent' &&
      priceCalendarDurationOptions &&
      priceCalendarDurationOptions.length > 0 &&
      labels.enablePriceCalendar &&
      (!selectedCabinClass || selectedCabinClass === 'Economy')
    );
  };

  renderRecommendedTag = () => {
    const { labels } = this.props;
    if (!labels.recommendedLabel) {
      return null;
    }
    return (
      <div className="bsh-date-picker__recommended-tag">
        <span>&#x2022;</span>
        {labels.recommendedLabel}
      </div>
    );
  };

  getHeaderContent({ isSpacer, showRecommendedDates }) {
    const {
      siteId,
      labels,
      selectedDuration,
      showPriceCalendar,
      setShowPriceCalendar,
      showDirectFlights,
      setShowDirectFlights,
      setSearchDirectOnly,
      priceCalendarDurationOptions,
      previousDuration,
      changeDuration,
      changeDurationForPriceCalendar,
    } = this.props;

    const headerContentClasses = ClassNames({
      'bsh-date-picker__header-content': true,
      'bsh-date-picker__header-content--spacer': isSpacer,
    });

    return (
      <div className={headerContentClasses}>
        {this.priceCalendarAvailable() && (
          <PriceCalendarControls
            labels={labels}
            selectedDuration={selectedDuration}
            showPriceCalendar={showPriceCalendar}
            setShowPriceCalendar={setShowPriceCalendar}
            showDirectFlights={showDirectFlights}
            setShowDirectFlights={setShowDirectFlights}
            setSearchDirectOnly={setSearchDirectOnly}
            priceCalendarDurationOptions={priceCalendarDurationOptions}
            previousDuration={previousDuration}
            changeDuration={changeDuration}
            changeDurationForPriceCalendar={changeDurationForPriceCalendar}
            renderDirectFlightDescription={(isMobile) =>
              this.renderDirectFlightDescription(isMobile)
            }
          />
        )}
        {!showPriceCalendar &&
          showRecommendedDates &&
          this.renderRecommendedTag()}
        <MonthHeader
          siteId={siteId}
          className="bsh-date-picker__month-header"
        />
        {!isSpacer && this.renderDatePickerHelpMessage()}
      </div>
    );
  }

  renderDatePickerHelpMessage() {
    const { selectedDuration, labels, isHotelOnly } = this.props;
    const { dateSelectionInProgress } = this.state;

    if (
      selectedDuration.bookingType === 'independent' &&
      selectedDuration.flexibleDuration
    ) {
      return (
        <DatePickerHelpMessages
          labels={labels}
          selectedDuration={selectedDuration}
          dateSelectionInProgress={dateSelectionInProgress}
          isHotelOnly={isHotelOnly}
        />
      );
    }

    return null;
  }

  renderDirectFlightDescription(isMobile) {
    const { showPriceCalendar, selectedDuration, labels } = this.props;
    if (
      !showPriceCalendar ||
      !selectedDuration.hasCalendar ||
      !selectedDuration.hasDirectFlights
    ) {
      return null;
    }

    const classes = ClassNames({
      'bsh-price-calendar__direct-flight-description': !isMobile,
      'bsh-price-calendar__direct-flight-description-mobile': isMobile,
    });
    return (
      <div className={classes}>
        <svg
          width="38"
          height="6"
          viewBox="0 0 38 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={MISC_DIRECT_FLIGHT_ARROW.default} fill="#454545" />
          <circle
            cx="2.5"
            cy="3"
            r="2.125"
            fill="white"
            stroke="#454545"
            strokeWidth="0.75"
          />
        </svg>
        {labels.directFlight}
      </div>
    );
  }

  render() {
    const {
      labels,
      isHidden,
      selectedDuration,
      range,
      selectableDepartureDates,
      monthSelector,
      dateSettings,
      showPriceCalendar,
      siteId,
      priceCalendarData,
      showDirectOnly,
      searchDirectOnly,
      setSearchDirectOnly,
      showLuggageIncluded,
      searchLuggageIncluded,
      setSearchLuggageIncluded,
      onlySelectableDates,
    } = this.props;
    const {
      dateSelectionInProgress,
      selectedDepartureDate,
      selectedReturnDate,
      selectedMonths,
    } = this.state;
    const rangeLengthInMonths = Math.ceil(
      range.max.diff(range.min, 'months', true),
    );
    const footerContent = (
      <Button
        variant="primary"
        className="bsh-date-picker__confirm-button"
        disabled={dateSelectionInProgress}
        onClick={() =>
          this.confirmDate(
            selectedDepartureDate,
            selectedReturnDate,
            selectedMonths,
          )
        }
      >
        {labels.datePickerConfirmLabel}
      </Button>
    );

    const { systemDateFormat } = dateSettings;
    const { flexibleDuration: isFlexible, bookingType } = selectedDuration;

    const isCharter = bookingType === bookingTypes.charter;
    const showRecommendedDates =
      !isCharter &&
      !isFlexible &&
      !onlySelectableDates &&
      selectableDepartureDates?.length > 0;

    return (
      <SelectOverlay
        heading={labels.dateLayerHeading}
        headingSubContent={
          <>
            {this.priceCalendarAvailable() &&
              this.renderDirectFlightDescription(false)}
            {!showPriceCalendar &&
              showRecommendedDates &&
              this.renderRecommendedTag()}
          </>
        }
        close={() => this.close()}
        isHidden={isHidden}
        footerContent={footerContent}
      >
        <>
          {this.getHeaderContent({ isSpacer: false, showRecommendedDates })}
          <div className="bsh-date-picker">
            {this.getHeaderContent({ isSpacer: true, showRecommendedDates })}

            <BaseDatePicker
              disableStickyMonthHeader
              startDate={range.min.format(systemDateFormat)}
              siteId={siteId}
              nrOfMonths={rangeLengthInMonths}
              validDates={
                !showRecommendedDates && !isFlexible
                  ? selectableDepartureDates
                  : []
              }
              recommendedDates={
                showRecommendedDates ? selectableDepartureDates : []
              }
              selectedDate={selectedDepartureDate.format(systemDateFormat)}
              selectedMonths={selectedMonths}
              selectedDateInterval={
                isFlexible
                  ? {
                      from: selectedDepartureDate.format(systemDateFormat),
                      to: dateSelectionInProgress
                        ? null
                        : selectedReturnDate.format(systemDateFormat),
                    }
                  : {}
              }
              onChange={(date) => this.selectDate(date)}
              onDynamicRangeChange={({ from, to, inProgress }) => {
                this.setState({
                  dateSelectionInProgress: inProgress,
                });

                if (inProgress) {
                  this.selectDate(from);
                } else {
                  this.selectDate(to);
                }
              }}
              useDynamicRangeSelect={selectedDuration.flexibleDuration}
              offers={showPriceCalendar ? priceCalendarData : []}
              monthFooter={
                monthSelector.show
                  ? ({ month, year, monthName, monthDateString }) => {
                      return (
                        <MonthFooter
                          selectedMonths={selectedMonths}
                          month={month}
                          year={year}
                          label={labels.monthCheckboxLabel}
                          monthName={monthName}
                          onSelect={this.toggleMonth}
                          monthDateString={monthDateString}
                        />
                      );
                    }
                  : () => {}
              }
            />
          </div>
          {(showDirectOnly || showLuggageIncluded) &&
            !showPriceCalendar &&
            selectedDuration?.bookingType === 'independent' && (
              <div className="bsh-date-picker__extended-options">
                <p className="bsh-date-picker__extended-opts-label">
                  {labels.showOnlyResultsWith || 'Show only results with:'}
                </p>
                <div className="bsh-date-picker__extended-opts-select-container">
                  {showDirectOnly && (
                    <Checkbox
                      checked={searchDirectOnly}
                      uniqueId="bs-search-direct-only"
                      onChange={() => setSearchDirectOnly(!searchDirectOnly)}
                    >
                      {labels.directFlights || 'Direct flights'}
                    </Checkbox>
                  )}
                  {showLuggageIncluded && (
                    <Checkbox
                      checked={searchLuggageIncluded}
                      uniqueId="bs-search-include-luggage"
                      onChange={() =>
                        setSearchLuggageIncluded(!searchLuggageIncluded)
                      }
                    >
                      {labels.includeLuggage || 'Incl. luggage'}
                    </Checkbox>
                  )}
                </div>
              </div>
            )}
          {showPriceCalendar && (
            <div className="bsh-price-calendar__price-description">
              {labels.priceDescription}
            </div>
          )}
        </>
      </SelectOverlay>
    );
  }
}

export default DatePicker;

DatePicker.propTypes = {
  labels: PropTypes.shape({
    dateLayerHeading: PropTypes.string.isRequired,
    priceCalendarExists: PropTypes.string.isRequired,
    priceCalendarChangeDurationLink: PropTypes.string.isRequired,
    priceCalendar: PropTypes.string.isRequired,
    showAllFlights: PropTypes.string.isRequired,
    showOnlyDirectFlights: PropTypes.string.isRequired,
    priceDescription: PropTypes.string.isRequired,
    priceDescriptionMobile: PropTypes.string.isRequired,
    showOnlyResultsWith: PropTypes.string.isRequired,
    directFlights: PropTypes.string.isRequired,
    includeLuggage: PropTypes.string.isRequired,
  }).isRequired,
  dateSettings: PropTypes.shape({}).isRequired,
  selectedDepartureDate: PropTypes.string.isRequired,
  selectedReturnDate: PropTypes.string,
  range: PropTypes.shape({}).isRequired,
  changeDate: PropTypes.func.isRequired,
  selectableDepartureDates: PropTypes.arrayOf(),
  selectedDuration: PropTypes.shape({
    bookingType: PropTypes.string.isRequired,
    flexibleDuration: PropTypes.bool.isRequired,
    hasCalendar: PropTypes.bool.isRequired,
    hasDirectFlights: PropTypes.bool.isRequired,
    defaultDuration: PropTypes.number.isRequired,
  }),
  close: PropTypes.func.isRequired,
  isHidden: PropTypes.bool,
  setDateSelectionInProgress: PropTypes.func.isRequired,
  setDepartureDateDuringSelection: PropTypes.func.isRequired,
  setReturnDateDuringSelection: PropTypes.func.isRequired,
  isHotelOnly: PropTypes.bool,
  monthSelector: PropTypes.shape({
    months: PropTypes.arrayOf(),
    show: PropTypes.bool,
    set: PropTypes.func,
  }),
  showPriceCalendar: PropTypes.bool,
  showDirectFlights: PropTypes.bool,
  setShowPriceCalendar: PropTypes.func,
  setShowDirectFlights: PropTypes.func,
  priceCalendarDurationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number,
    }),
  ),
  changeDurationForPriceCalendar: PropTypes.func,
  priceCalendarData: PropTypes.arrayOf(PropTypes.shape()),
  previousDuration: PropTypes.shape(),
  changeDuration: PropTypes.func,
  siteId: PropTypes.number.isRequired,
  showDirectOnly: PropTypes.bool,
  searchDirectOnly: PropTypes.bool,
  setSearchDirectOnly: PropTypes.func,
  showLuggageIncluded: PropTypes.bool,
  searchLuggageIncluded: PropTypes.bool,
  setSearchLuggageIncluded: PropTypes.func,
  selectedCabinClass: PropTypes.string,
  onlySelectableDates: PropTypes.bool,
};

DatePicker.defaultProps = {
  selectedDuration: {},
  isHotelOnly: false,
  monthSelector: {
    months: [],
    set: () => {},
    show: false,
  },
  isHidden: false,
  selectableDepartureDates: [],
  showPriceCalendar: false,
  showDirectFlights: false,
  setShowPriceCalendar: () => {},
  setShowDirectFlights: () => {},
  priceCalendarDurationOptions: [],
  priceCalendarData: [],
  changeDurationForPriceCalendar: () => {},
  roomDistribution: null,
  selectedReturnDate: null,
  previousDuration: null,
  changeDuration: () => {},
  showDirectOnly: false,
  searchDirectOnly: false,
  setSearchDirectOnly: () => {},
  showLuggageIncluded: false,
  searchLuggageIncluded: false,
  setSearchLuggageIncluded: () => {},
  selectedCabinClass: null,
  onlySelectableDates: false,
};
