import fetch from 'isomorphic-fetch';

export const fetchAttributeCollection = async (baseUrl, siteId, alias) => {
  const url = `${baseUrl}/attributecollection/${siteId}/${alias}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  }).then((response) => {
    if (response.status >= 400) {
      throw new Error(
        `Bad response from server while fetching attribute collection for alias: ${alias}, siteId: ${siteId}.`,
      );
    }
    return response.json();
  });
};
