import store from '../../stores/bookingstart';
import * as cruiseService from '../../../services/cruise';
import { ActionTypes } from '../../constants/actionTypes/bookingstart';
import { getSelectedDepartureDate } from '../../../utils/actionHelper';

export const initCruiseSection = (preSelectedSettings) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CRUISE_INIT_SECTION,
    });

    const data = await cruiseService.getInitState(preSelectedSettings);

    dispatch({
      type: ActionTypes.CRUISE_INIT_SECTION_SUCCESS,
      data,
    });
  };
};

export const getDepartureDates = (selectedValues) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CRUISE_GET_DEPARTURE_DATES,
    });

    const { selectedDeparture, selectedArea, selectedDepartureDate } = selectedValues;

    const departureDates = await cruiseService.getDepartureDates(selectedDeparture.itemId, selectedArea.itemId);
    const departureDate = getSelectedDepartureDate(departureDates, selectedDepartureDate);

    dispatch({
      type: ActionTypes.CRUISE_GET_DEPARTURE_DATES_SUCCESS,
      departureDates,
      selectedDepartureDate: departureDate,
    });
  };
};

export const getAreas = (selectedValues) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CRUISE_GET_AREAS,
    });

    const { selectedDeparture, selectedArea } = selectedValues;

    const areas = await cruiseService.getAreas(selectedDeparture.itemId);
    let area = areas[0];

    for (let i = 0; i < areas.length; i += 1) {
      if (areas[i].itemId === selectedArea.itemId) {
        area = areas[i];
        break;
      }
    }

    dispatch({
      type: ActionTypes.CRUISE_GET_AREAS_SUCCESS,
      areas,
      selectedArea: area,
    });

    const newSelectedValues = {
      ...selectedValues,
      selectedArea: area,
    };

    dispatch(getDepartureDates(newSelectedValues));
  };
};


export const changeDeparture = (departure) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CRUISE_CHANGE_DEPARTURE,
      departure,
    });

    const selectedValues = {
      ...store.getState().cruise,
      selectedDeparture: departure,
    };

    dispatch(getAreas(selectedValues));
  };
};

export const changeDepartureDate = (date) => {
  return {
    type: ActionTypes.CRUISE_CHANGE_DEPARTURE_DATES,
    departureDate: date,
  };
};

export const changeArea = (area) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CRUISE_CHANGE_AREA,
      area,
    });

    const selectedValues = {
      ...store.getState().cruise,
      selectedArea: area,
    };

    dispatch(getDepartureDates(selectedValues));
  };
};

export const changeRoomDistribution = (roomDistribution) => {
  return {
    type: ActionTypes.CRUISE_CHANGE_ROOM_DISTRIBUTION,
    roomDistribution,
  };
};
