import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './departureSelect.scss';

const NUMBER_OF_COLUMNS = 3;

const AirportItem = ({ airport, changeAirport, selectedAirport }) => {
  const airportClasses = ClassNames({
    'departure-select__item': true,
    'departure-select__item--selected': airport.itemId == selectedAirport.itemId,
  });

  return (
    <button
      className={airportClasses}
      onClick={() => changeAirport(airport)}
      type="button"
    >
      {airport.name}
    </button>
  );
};

const Column = ({ airports, ...otherProps }) => {
  return (
    <div className="departure-select__column">
      {airports.map((airport, index) => {
        return <AirportItem key={index} airport={airport} {...otherProps} />;
      })}
    </div>
  );
};

const DepartureSelect = ({ airportList, ...otherProps }) => {
  const minAirportsPerCol = Math.floor(airportList.length / NUMBER_OF_COLUMNS);
  let rest = airportList.length - minAirportsPerCol * NUMBER_OF_COLUMNS;
  const columns = [];
  let counter = 0;

  for (let i = 0; i < NUMBER_OF_COLUMNS; i++) {
    let numberOfItems = minAirportsPerCol;
    const airports = airportList.slice();

    if (rest > 0) {
      numberOfItems++;
      rest--;
    }

    const airportsInColumn = airports.slice(counter, counter + numberOfItems);
    columns.push(airportsInColumn);
    counter += numberOfItems;
  }

  return (
    <div className="departure-select">
      {columns.map((airportsInColumn, index) => {
        return <Column key={index} airports={airportsInColumn} {...otherProps} />;
      })}
    </div>
  );
};

DepartureSelect.propTypes = {
  airportList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      itemId: PropTypes.number.isRequired,
    })
  ).isRequired,
  selectedAirport: PropTypes.object.isRequired,
  changeAirport: PropTypes.func.isRequired,
};

export default DepartureSelect;
