import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIsGlobetrotter } from '@bwoty-web/ui-kit/hooks';

import './bookingstart.scss';

const BookingstartPlaceholder = ({
  hideProductSelector,
  hideHeading,
  searchResultMode,
}) => {
  const noTopContainer =
    !searchResultMode && hideHeading && hideProductSelector;
  const noProductSelector =
    !searchResultMode && hideProductSelector && !hideHeading;

  const classes = classNames('bs-placeholder', {
    'bs-placeholder--globetrotter': useIsGlobetrotter(),
    'bs-placeholder--search-result-mode': searchResultMode,
    'bs-placeholder--no-top-container': noTopContainer,
    'bs-placeholder--no-product-selector': noProductSelector,
  });

  return <div className={classes} />;
};

BookingstartPlaceholder.propTypes = {
  hideProductSelector: PropTypes.bool,
  hideHeading: PropTypes.bool,
  searchResultMode: PropTypes.bool,
};

BookingstartPlaceholder.defaultProps = {
  hideProductSelector: false,
  hideHeading: false,
  searchResultMode: false,
};

export default BookingstartPlaceholder;
