import qs from 'qs';

const queryState = {};
let hasParsedInitialQuery = false;

const parseInitialQuery = () => {
  const parsedPreexistingQuery = qs.parse(window.location.search.split('?').pop());

  Object.keys(parsedPreexistingQuery).forEach((key) => {
    queryState[key] = parsedPreexistingQuery[key];
  });

  hasParsedInitialQuery = true;
};

export const getQueryKeyValue = (key) => {
  if (!hasParsedInitialQuery) {
    parseInitialQuery();
  }

  return queryState[key] || '';
};
