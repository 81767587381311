import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Icon } from '@bwoty-web/ui-kit';
import { UI_CROSS } from '@bwoty-web/ui-kit/icons';
import { Component } from 'react';

import { isSmallScreen } from '../../utils/device';

import './selectOverlay.scss';

export default class SelectOverlay extends Component {
  constructor() {
    super();
    this.startY = -1;
    this.hideIfClickOutside = this.hideIfClickOutside.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (isSmallScreen() && this.props.isHidden != nextProps.isHidden) {
      if (nextProps.isHidden) {
        this.removeBodyNoScrollClass();
      } else {
        this.addBodyNoScrollClass();
      }
    }

    if (!isSmallScreen() && this.props.isHidden != nextProps.isHidden) {
      if (nextProps.isHidden) {
        this.removeClickOutsideListener();
      } else {
        this.addClickOutsideListener();
      }
    }
  }

  componentDidMount() {
    if (isSmallScreen() && !this.props.isHidden) {
      this.addBodyNoScrollClass();
    }

    if (!this.props.isHidden) {
      this.addClickOutsideListener();
    }
  }

  componentWillUnmount() {
    this.removeBodyNoScrollClass();
    this.removeClickOutsideListener();
  }

  addClickOutsideListener() {
    if (!isSmallScreen()) {
      document.addEventListener('click', this.hideIfClickOutside, true);
    }
  }

  removeClickOutsideListener() {
    if (!isSmallScreen()) {
      document.removeEventListener('click', this.hideIfClickOutside, true);
    }
  }

  hideIfClickOutside(event) {
    const { clickedOutside, close } = this.props;

    const sameFieldIsClicked = this.refs.SelectOverlay.parentElement === event.target.parentElement;

    if (this.refs.SelectOverlay && !this.refs.SelectOverlay.contains(event.target) && !sameFieldIsClicked) {
      if (clickedOutside) {
        clickedOutside(event);
      } else {
        close();
      }
    }
  }

  addBodyNoScrollClass() {
    document.body.className += ' bookingstart-horizontal-noscroll';
  }

  removeBodyNoScrollClass() {
    document.body.className = document.body.className.replace('bookingstart-horizontal-noscroll', '');
  }

  handleTouchMove(event) {
    const mainContent = this.refs.MainContent;

    if (mainContent.contains(event.nativeEvent.target)) {
      let swipedDown;
      const maxScrollTop = mainContent.scrollHeight - mainContent.clientHeight;
      const minScrollTop = 0;
      const contentIsInTop = mainContent.scrollTop <= minScrollTop;
      const contentIsInBottom = mainContent.scrollTop >= maxScrollTop;
      const currentY = event.nativeEvent.changedTouches[0].clientY;

      if (currentY < this.startY) {
        swipedDown = false;
      } else if (currentY > this.startY) {
        swipedDown = true;
      }

      if (contentIsInBottom && !swipedDown) {
        event.preventDefault();
      } else if (contentIsInTop && swipedDown) {
        event.preventDefault();
      }

      this.startY = currentY;
    }
  }

  handleTouchStart(event) {
    const mainContent = this.refs.MainContent;
    if (mainContent.contains(event.nativeEvent.target)) {
      this.startY = event.nativeEvent.touches[0].clientY;
    }
  }

  render() {
    const { heading, headingSubContent, close, isHidden, headerContent, footerContent, children } = this.props;

    const selectOverlayClasses = ClassNames({
      'select-overlay': true,
      'select-overlay--hidden': isHidden,
    });

    return (
      <div className={selectOverlayClasses} ref="SelectOverlay">
        <button
          className="select-overlay__close-button"
          aria-label="Close"
          type="button"
          onClick={() => close()}
          lang="en"
        >
          <Icon
            path={UI_CROSS}
            className="select-overlay__close-button-icon"
            size="xs"
            sizeMobile="xs"
            aria-hidden="true"
          />
        </button>
        <Header heading={heading} headingSubContent={headingSubContent} headerContent={headerContent} close={close} />
        <div
          className="select-overlay__content-container"
          ref="MainContent"
          onTouchMove={isSmallScreen() ? event => this.handleTouchMove(event) : null}
          onTouchStart={isSmallScreen() ? event => this.handleTouchStart(event) : null}
        >
          <div className="select-overlay__content">{children}</div>
        </div>
        <Footer footerContent={footerContent} />
      </div>
    );
  }
}

SelectOverlay.propTypes = {
  heading: PropTypes.string,
  headingSubContent: PropTypes.object,
  close: PropTypes.func.isRequired,
  clickedOutside: PropTypes.func,
  isHidden: PropTypes.bool,
  headerContent: PropTypes.object,
  footerContent: PropTypes.object,
};

const Header = ({ heading, headingSubContent, headerContent, close }) => {
  return (
    <div className="select-overlay__header-container">
      <div className="select-overlay__heading-container">
        <h2 className="select-overlay__heading">{heading}</h2>
        {headingSubContent}
      </div>
      {headerContent}
    </div>
  );
};

const Footer = ({ footerContent }) => {
  const footerContentClasses = ClassNames({
    'select-overlay__footer-container': true,
    'select-overlay__footer-container--no-content': !footerContent,
  });
  return (
    <div className={footerContentClasses}>
      <div className="select-overlay__footer">{footerContent}</div>
    </div>
  );
};
