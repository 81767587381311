import { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ClassNames from 'classnames';
import { Button } from '@bwoty-web/ui-kit';

import * as CruiseActions from '../redux/actions/bookingstart/cruise';
import * as BookingstartActions from '../redux/actions/bookingstart/bookingstart';
import { validRoomDistribution } from '../utils/pax/validation';
import * as cruiseService from '../services/cruise';
import { ScrollElementToBottomOfScreen } from '../utils/scroll';
import SelectOverlay from '../components/selectOverlay/SelectOverlay';
import DepartureField from '../components/departure/DepartureField';
import DepartureSelect from '../components/departure/DepartureSelect';
import DestinationField from '../components/destination/DestinationField';
import DestinationSimpleSelect from '../components/destination/DestinationSimpleSelect';
import DateField from '../components/date/DateField';
import DatePicker from '../components/date/DatePicker';
import PaxField from '../components/pax/PaxField';
import PaxSelect from '../components/pax/PaxSelect';
import { maxNumberOfPax } from '../constants/pax';

class CruiseSection extends Component {
  constructor(props) {
    super();

    this.state = {
      showDepartureLayer: false,
      showDurationLayer: false,
      showDateLayer: false,
      showDestinationLayer: false,
      showPaxLayer: false,
      paxIsValid: true,
    };

    this.departureRange = {
      min: moment().add(props.siteSettings.datePicker.cruiseBookDaysAhead, 'days'),
      max: moment().add(props.siteSettings.datePicker.cruiseMaxDaysToDeparture, 'days'),
    };

    this.setDate = this.setDate.bind(this);
    this.toggleDateLayer = this.toggleDateLayer.bind(this);
  }

  isSelectOverlayOpen() {
    return this.state.showDepartureLayer || this.state.showDurationLayer || this.state.showDateLayer || this.state.showDestinationLayer || this.state.showPaxLayer;
  }

  toggleDepartureLayer() {
    this.setState({ showDepartureLayer: !this.state.showDepartureLayer });
  }

  setDeparture(departure) {
    this.props.dispatch(CruiseActions.changeDeparture(departure));
    this.toggleDepartureLayer();
  }

  toggleDestinationLayer() {
    this.setState({ showDestinationLayer: !this.state.showDestinationLayer });
  }

  setArea(area) {
    this.props.dispatch(CruiseActions.changeArea(area));
    this.toggleDestinationLayer();
  }

  setDate(departureDate) {
    this.props.dispatch(CruiseActions.changeDepartureDate(departureDate));
  }

  toggleDateLayer() {
    if (this.state.showDateLayer) {
      this.setState({ dateSelectionInProgress: false, departureDateSelected: false });
    }
    this.setState({ showDateLayer: !this.state.showDateLayer });
  }

  togglePaxLayer(paxIsValid = true) {
    this.setState({
      showPaxLayer: !this.state.showPaxLayer,
      paxIsValid,
    });
    if (window.innerWidth < 768) {
      ScrollElementToBottomOfScreen(document.getElementsByClassName('bs-form__search-button')[0]);
    }
  }

  setRoomDistribution(roomDistribution) {
    this.props.dispatch(CruiseActions.changeRoomDistribution(roomDistribution));
    this.togglePaxLayer();
  }

  navigateToResultPage() {
    this.props.dispatch(BookingstartActions.searchInit());

    cruiseService.getSearchUrl(this.props).then((url) => {
      window.location.href = url;
    });
  }

  searchCruise() {
    if (this.state.showPaxLayer) {
      // Wait to the pax layer is closed
      setTimeout(() => {
        if (validRoomDistribution(this.props.roomDistribution)) {
          this.navigateToResultPage();
        } else {
          this.togglePaxLayer(false);
        }
      }, 10);
    } else if (validRoomDistribution(this.props.roomDistribution)) {
      this.navigateToResultPage();
    } else {
      this.togglePaxLayer(false);
    }
  }

  render() {
    const {
      labels,
      loading,
      selectedDeparture,
      selectedDepartureDate,
      departureDates,
      departureList,
      selectedArea,
      cruiseAreas,
      roomDistribution,
      selectedNumberOfRooms,
      type,
      searchHistory,
    } = this.props;

    const { showDurationField, showDepartureLayer, showDestinationLayer, paxIsValid, showDateLayer, showPaxLayer } = this.state;

    const cruiseSectionClasses = ClassNames({
      'bs-form': true,
      'bs-form--cruise': true,
      'bs-form--no-duration-field': !showDurationField,
    });

    return (
      <div className={cruiseSectionClasses}>
        <DepartureField
          labels={labels}
          isLoading={loading}
          isActive={showDepartureLayer}
          isOtherFieldActive={!showDepartureLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDepartureLayer()}
          selectedAirport={selectedDeparture}
        >
          {!loading && (
            <SelectOverlay
              heading={labels.departureLayerHeading}
              close={() => this.toggleDepartureLayer()}
            >
              <DepartureSelect
                airportList={departureList}
                selectedAirport={selectedDeparture}
                changeAirport={departure => this.setDeparture(departure)}
              />
            </SelectOverlay>
          )}
        </DepartureField>

        <DestinationField
          labels={labels}
          isLoading={loading}
          isActive={showDestinationLayer}
          isOtherFieldActive={!showDestinationLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDestinationLayer()}
          selectedDestination={selectedArea}
        >
          {!loading && (
            <SelectOverlay
              heading={labels.destinationLayerHeading}
              close={() => this.toggleDestinationLayer()}
            >
              <DestinationSimpleSelect
                labels={labels}
                destinationList={cruiseAreas}
                setDestination={area => this.setArea(area)}
                selectedDestination={selectedArea}
              />
            </SelectOverlay>
          )}
        </DestinationField>

        <DateField
          dateSettings={this.props.siteSettings.datePicker}
          labels={labels}
          isLoading={loading}
          isActive={showDateLayer}
          isOtherFieldActive={!showDateLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDateLayer()}
          showDateField
          datePickerIsActive={showDateLayer}
          selectedDepartureDate={selectedDepartureDate}
          hideReturnDate
          isHotelOnly={false}
        >
          {!loading && showDateLayer && (
            <DatePicker
              dateSettings={this.props.siteSettings.datePicker}
              selectedDepartureDate={selectedDepartureDate}
              selectedDuration={{ flexibleDuration: false }}
              range={this.departureRange}
              changeDate={this.setDate}
              selectableDepartureDates={departureDates}
              close={this.toggleDateLayer}
              isHidden={!this.state.showDateLayer}
              labels={labels}
              isHotelOnly={false}
              siteId={this.props.siteSettings.siteId}
              onlySelectableDates
            />
          )}
        </DateField>
        <PaxField
          labels={labels}
          isLoading={loading}
          isActive={showPaxLayer}
          isOtherFieldActive={!showPaxLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.togglePaxLayer()}
          showPaxField
          roomDistribution={roomDistribution}
        >
          {!loading && (
            <PaxSelect
              labels={labels}
              numberOfRooms={selectedNumberOfRooms}
              roomDistribution={roomDistribution}
              changeRoomDistribution={rooms => this.setRoomDistribution(rooms)}
              multipleRooms={false}
              preferAllPaxInSameRoom={false}
              maxNumberOfPax={maxNumberOfPax.cruise}
              activeValidation={!paxIsValid}
              close={() => this.togglePaxLayer()}
              hideSameRoomCheckbox
              isCruise
            />
          )}
        </PaxField>

        <div className="bs-form__button-container">
          <Button
            variant="primary"
            disabled={loading}
            onClick={() => this.searchCruise()}
            rel="nofollow"
            className="bs-form__search-button"
          >
            {labels.searchButtonText}
          </Button>
        </div>
      </div>
    );
  }
}

function select(state) {
  return {
    loading: state.cruise.loading || !state.bookingstart.cruiseSectionInitialized,
    siteSettings: state.bookingstart.siteSettings,
    labels: state.bookingstart.labels,
    departureList: state.cruise.departureList,
    cruiseAreas: state.cruise.cruiseAreas,
    departureDates: state.cruise.departureDates,
    roomDistribution: state.cruise.rooms,
    selectedDeparture: state.cruise.selectedDeparture,
    selectedArea: state.cruise.selectedArea,
    selectedDepartureDate: state.cruise.selectedDepartureDate,
    selectedNumberOfRooms: state.cruise.selectedNumberOfRooms,
    type: state.cruise.type,
    searchHistory: state.cruise.searchHistory,
  };
}

export default connect(select)(CruiseSection);
