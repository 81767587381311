import { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ClassNames from 'classnames';
import { Button } from '@bwoty-web/ui-kit';

import * as FlightActions from '../redux/actions/bookingstart/flight';
import * as BookingstartActions from '../redux/actions/bookingstart/bookingstart';
import * as flightService from '../services/flight';
import { validRoomDistribution } from '../utils/pax/validation';
import { ScrollBsIntoViewIfNeeded, ScrollElementToBottomOfScreen } from '../utils/scroll';
import SelectOverlay from '../components/selectOverlay/SelectOverlay';
import DepartureField from '../components/departure/DepartureField';
import DepartureSelect from '../components/departure/DepartureSelect';
import DestinationField from '../components/destination/DestinationField';
import DestinationSelect from '../components/destination/DestinationSelect';
import DurationField from '../components/duration/DurationField';
import DurationSelect from '../components/duration/DurationSelect';
import DatePicker from '../components/date/DatePicker';
import DateField from '../components/date/DateField';
import PaxField from '../components/pax/PaxField';
import PaxSelect from '../components/pax/PaxSelect';
import { maxNumberOfPax } from '../constants/pax';
import bookingTypes from '../constants/bookingTypes';

import './flightSection.scss';

class FlightSection extends Component {
  constructor(props) {
    super();

    this.state = {
      showDepartureLayer: false,
      showDurationLayer: false,
      showDateLayer: false,
      showDestinationLayer: false,
      showPaxLayer: false,
      showDurationField: this.showDurationField(props.durationList),
      dateSelectionInProgress: false,
      departureDateDuringSelection: null,
      returnDateDuringSelection: null,
      paxIsValid: true,
    };

    this.independentRange = {
      min: moment().add(props.siteSettings.datePicker.dpBookDaysAhead, 'days'),
      max: moment().add(props.siteSettings.datePicker.dpMaxDaysToReturn, 'days'),
    };

    this.charterRange = {
      min: moment(),
      max: moment().add(19, 'month').endOf('month'),
    };

    this.setDate = this.setDate.bind(this);
    this.toggleDateLayer = this.toggleDateLayer.bind(this);
    this.setDateSelectionInProgress = this.setDateSelectionInProgress.bind(this);
    this.setDepartureDateDuringSelection = this.setDepartureDateDuringSelection.bind(this);
    this.setReturnDateDuringSelection = this.setReturnDateDuringSelection.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ showDurationField: this.showDurationField(props.durationList) });
  }

  showDurationField(durationList) {
    if (!durationList) {
      return true;
    }

    const numberOfDurationTypes = durationList.length;
    return numberOfDurationTypes > 1 || (numberOfDurationTypes === 1 && !durationList[0].flexibleDuration);
  }

  getDateRange(bookingType) {
    return bookingType == 'independent' ? this.independentRange : this.charterRange;
  }

  showCompressedBookingStart() {
    return false;
    // return this.props.isCompressed && window.innerWidth < 768;
  }

  setDepartureAirport(airport) {
    this.props.dispatch(FlightActions.changeDepartureAirport(airport));
    this.toggleDepartureLayer();
  }

  setDestination(destination) {
    this.props.dispatch(FlightActions.changeDestination(destination));
    this.toggleDestinationLayer();
    ScrollBsIntoViewIfNeeded();
  }

  searchDestinations(searchTerm) {
    const { selectedDepartureAirport } = this.props;
    this.props.dispatch(FlightActions.searchDestinations(selectedDepartureAirport.itemId, searchTerm));
  }

  setDuration(duration) {
    this.props.dispatch(FlightActions.changeDuration(duration));
    this.toggleDurationLayer();
  }

  setDate(departureDate, returnDate) {
    if (returnDate != null) {
      this.props.dispatch(FlightActions.changeDates(departureDate, returnDate));
    } else {
      this.props.dispatch(FlightActions.changeDepartureDate(departureDate));
    }
  }

  setDepartureDateDuringSelection(departureDate) {
    this.setState({ departureDateDuringSelection: departureDate });
  }

  setReturnDateDuringSelection(returnDate) {
    this.setState({ returnDateDuringSelection: returnDate });
  }

  setDateSelectionInProgress(dateSelectionInProgress) {
    this.setState({ dateSelectionInProgress });
  }

  setPassengerCombination(roomDistribution) {
    this.props.dispatch(FlightActions.changePassengerCombination(roomDistribution[0].numberOfAdults, roomDistribution[0].numberOfChildren, roomDistribution[0].childAges));
    this.togglePaxLayer();
  }

  isSelectOverlayOpen() {
    return this.state.showDepartureLayer || this.state.showDurationLayer || this.state.showDateLayer || this.state.showDestinationLayer || this.state.showPaxLayer;
  }

  toggleDepartureLayer() {
    this.setState({ showDepartureLayer: !this.state.showDepartureLayer });
  }

  toggleDestinationLayer() {
    this.setState({ showDestinationLayer: !this.state.showDestinationLayer });
  }

  toggleDurationLayer() {
    this.setState({ showDurationLayer: !this.state.showDurationLayer });
  }

  toggleDateLayer() {
    if (this.state.showDateLayer && this.state.returnDateDuringSelection == null) {
      this.setDateSelectionInProgress(false);
      this.setDepartureDateDuringSelection(null);
    }
    this.setState({ showDateLayer: !this.state.showDateLayer });
  }

  togglePaxLayer(paxIsValid = true) {
    this.setState({
      showPaxLayer: !this.state.showPaxLayer,
      paxIsValid,
    });
    if (window.innerWidth < 768) {
      ScrollElementToBottomOfScreen(document.getElementsByClassName('bs-form__search-button')[0]);
    }
  }

  navigateToResultPage() {
    this.props.dispatch(BookingstartActions.searchInit());

    flightService.getSearchUrl(this.props).then((url) => {
      window.location.href = url;
    });
  }

  searchFlight() {
    if (this.state.showPaxLayer) {
      // Wait to the pax layer is closed
      setTimeout(() => {
        if (validRoomDistribution([this.props.selectedPassengerCombination])) {
          this.navigateToResultPage();
        } else {
          this.togglePaxLayer(false);
        }
      }, 10);
    } else if (validRoomDistribution([this.props.selectedPassengerCombination])) {
      this.navigateToResultPage();
    } else {
      this.togglePaxLayer(false);
    }
  }

  renderOneWayFlightLink() {
    if (this.props.isLoading) {
      return null;
    }
    return (
      <div className="bs-form--flight__one-way-flight-link">
        <a href={this.props.oneWaySearchUrl}>
          {this.props.labels.oneWayFlightSearchText}
        </a>
      </div>
    );
  }

  toggleCompressedBookingStart() {
    this.setState({ isCompressed: !this.state.isCompressed });
    this.props.expandBookingStart();
  }

  render() {
    const {
      dispatch,
      labels,
      isLoading,
      departureAirportList,
      countryList,
      durationList,
      departureDates,
      selectedDepartureAirport,
      selectedDestination,
      selectedDuration,
      selectedDepartureDate,
      searchResultsDestination,
      selectedReturnDate,
      selectedPassengerCombination,
      searchHistory,
      searchInformation,
      showCabinClasses,
      selectedCabinClass,
      showDirectOnly,
      searchDirectOnly,
      showLuggageIncluded,
      searchLuggageIncluded,
    } = this.props;
    const {
      showDepartureLayer,
      showDestinationLayer,
      showDurationLayer,
      showDurationField,
      showDateLayer,
      showPaxLayer,
      dateSelectionInProgress,
      departureDateDuringSelection,
      returnDateDuringSelection,
      paxIsValid,
    } = this.state;

    const flightSectionClasses = ClassNames('bs-form', 'bs-form--flight', { 'bs-form--no-duration-field': !this.state.showDurationField });

    return (
      <div className={flightSectionClasses}>
        <DepartureField
          labels={labels}
          isLoading={isLoading}
          isActive={showDepartureLayer}
          isOtherFieldActive={!showDepartureLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDepartureLayer()}
          selectedAirport={selectedDepartureAirport}
        >
          {!isLoading && (
            <SelectOverlay
              heading={labels.departureLayerHeading}
              close={() => this.toggleDepartureLayer()}
            >
              <DepartureSelect
                airportList={departureAirportList}
                selectedAirport={selectedDepartureAirport}
                changeAirport={airport => this.setDepartureAirport(airport)}
              />
            </SelectOverlay>
          )}
        </DepartureField>

        <DestinationField
          labels={labels}
          isLoading={isLoading}
          isActive={showDestinationLayer}
          isOtherFieldActive={!showDestinationLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDestinationLayer()}
          selectedDestination={selectedDestination}
          countryList={countryList}
        >
          {!isLoading && (
            <SelectOverlay
              heading={labels.destinationLayerHeading}
              close={() => this.toggleDestinationLayer()}
            >
              <DestinationSelect
                countries={countryList}
                selectedDestination={selectedDestination}
                setDestination={destination => this.setDestination(destination)}
                searchDestinations={searchString => this.searchDestinations(searchString)}
                searchResult={searchResultsDestination}
                labels={labels}
              />
            </SelectOverlay>
          )}
        </DestinationField>

        <DurationField
          labels={labels}
          isLoading={isLoading}
          isActive={showDurationLayer}
          isOtherFieldActive={!showDurationLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDurationLayer()}
          selectedDuration={selectedDuration}
          showDurationField={showDurationField}
        >
          {!isLoading && (
            <SelectOverlay
              heading={labels.durationLayerHeading}
              close={() => this.toggleDurationLayer()}
            >
              <DurationSelect
                durationList={durationList}
                selectedDuration={selectedDuration}
                changeDuration={duration => this.setDuration(duration)}
                labels={labels}
                isFlightDuration
              />
            </SelectOverlay>
          )}
        </DurationField>

        <DateField
          dateSettings={this.props.siteSettings.datePicker}
          labels={labels}
          isLoading={isLoading}
          isActive={showDateLayer}
          isOtherFieldActive={!showDateLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDateLayer()}
          selectedDepartureDate={selectedDepartureDate}
          selectedReturnDate={selectedReturnDate}
          selectedDuration={selectedDuration}
          datePickerIsActive={this.state.showDateLayer}
          dateSelectionInProgress={dateSelectionInProgress}
          setDateSelectionInProgress={dateSelectionInProgress => this.setDateSelectionInProgress(dateSelectionInProgress)}
          departureDateDuringSelection={departureDateDuringSelection}
          returnDateDuringSelection={returnDateDuringSelection}
          isHotelOnly={false}
        >
          {!isLoading && showDateLayer && (
            <DatePicker
              dateSettings={this.props.siteSettings.datePicker}
              selectedDepartureDate={selectedDepartureDate}
              selectedReturnDate={selectedReturnDate}
              range={this.getDateRange(selectedDuration.bookingType)}
              changeDate={this.setDate}
              selectableDepartureDates={departureDates}
              selectedDuration={selectedDuration}
              close={this.toggleDateLayer}
              isHidden={!this.state.showDateLayer}
              labels={labels}
              setDateSelectionInProgress={this.setDateSelectionInProgress}
              setDepartureDateDuringSelection={this.setDepartureDateDuringSelection}
              setReturnDateDuringSelection={this.setReturnDateDuringSelection}
              isHotelOnly={false}
              siteId={this.props.siteSettings.siteId}
              showDirectOnly={showDirectOnly}
              searchDirectOnly={searchDirectOnly}
              setSearchDirectOnly={toggle => dispatch(FlightActions.setSearchDirectOnly(toggle))}
              showLuggageIncluded={showLuggageIncluded}
              searchLuggageIncluded={searchLuggageIncluded}
              setSearchLuggageIncluded={toggle => dispatch(FlightActions.setSearchLuggageIncluded(toggle))}
              selectedCabinClass={selectedCabinClass}
            />
          )}
        </DateField>

        <PaxField
          labels={labels}
          isLoading={isLoading}
          isActive={showPaxLayer}
          isOtherFieldActive={!showPaxLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.togglePaxLayer()}
          roomDistribution={[selectedPassengerCombination]}
          selectedCabinClass={selectedCabinClass}
        >
          {!isLoading && (
            <PaxSelect
              labels={labels}
              numberOfRooms={1}
              roomDistribution={[selectedPassengerCombination]}
              changeRoomDistribution={roomDistribution => this.setPassengerCombination(roomDistribution)}
              multipleRooms={false}
              preferAllPaxInSameRoom={false}
              maxNumberOfPax={maxNumberOfPax.charter}
              activeValidation={!paxIsValid}
              close={() => this.togglePaxLayer()}
              hideSameRoomCheckbox
              showCabinClasses={showCabinClasses}
              selectedCabinClass={selectedCabinClass}
              setCabinClass={(cabinclass) => 
                dispatch(FlightActions.setCabinClass(cabinclass))
              }
              isDP={selectedDuration.bookingType === bookingTypes.dp}
            />
          )}
        </PaxField>

        {!isLoading && (
          <div className="bs-form--flight__one-way-flight-link">
            <a href={this.props.oneWaySearchUrl}>
              {this.props.labels.oneWayFlightSearchText}
            </a>
          </div>
        )}

        <div className="bs-form__button-container">
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={() => this.searchFlight()}
            rel="nofollow"
            className="bs-form__search-button"
          >
            {labels.searchButtonText}
          </Button>
        </div>
      </div>
    );
  }
}

function select(state) {
  return {
    isLoading: state.flight.loading || !state.bookingstart.flightSectionInitialized,
    siteSettings: state.bookingstart.siteSettings,
    departureAirportList: state.flight.departureAirportList,
    searchResultsDepartureAirport: state.flight.searchResultsDepartureAirport,
    searchResultsDestination: state.flight.searchResultsDestination,
    countryList: state.flight.countryList,
    destinationAirportList: state.flight.destinationAirportList,
    durationList: state.flight.durationList,
    departureDates: state.flight.departureDates,
    returnDates: state.flight.returnDates,
    selectedDepartureAirport: state.flight.selectedDepartureAirport,
    selectedDestination: state.flight.selectedDestinationAirport,
    selectedDuration: state.flight.selectedDuration,
    selectedDepartureDate: state.flight.selectedDepartureDate,
    selectedReturnDate: state.flight.selectedReturnDate,
    selectedPassengerCombination: state.flight.selectedPassengerCombination,
    oneWaySearchUrl: state.flight.oneWaySearchUrl,
    labels: state.bookingstart.labels,
    searchHistory: state.flight.searchHistory,
    searchInformation: state.flight.searchInformation,
    showDirectOnly: state.flight.showDirectOnly,
    searchDirectOnly: state.flight.searchDirectOnly,
    showLuggageIncluded: state.flight.showLuggageIncluded,
    searchLuggageIncluded: state.flight.searchLuggageIncluded,
    showCabinClasses: state.flight.showCabinClasses,
    selectedCabinClass: state.flight.selectedCabinClass,
  };
}

export default connect(select)(FlightSection);
