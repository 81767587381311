import PropTypes from 'prop-types';
import FormField from '../formField/FormField';
import FormFieldLoader from '../formField/FormFieldLoader';

const DurationField = (props) => {
  const {
    children,
    labels,
    isLoading,
    isActive,
    isOtherFieldActive,
    toggleOverlay,
    selectedDuration,
    showDurationField,
  } = props;

  if (!showDurationField) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="bs-form__select bs-form__duration-select">
        <FormFieldLoader label={labels.durationInputLabel} />
      </div>
    );
  }

  return (
    <div className="bs-form__select bs-form__duration-select">
      <FormField value={selectedDuration.name} label={labels.durationInputLabel} click={() => toggleOverlay()} isActive={isActive} isOtherFieldActive={isOtherFieldActive} />

      {isActive ? children : null}
    </div>
  );
};

DurationField.propTypes = {
  children: PropTypes.node,
  labels: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isOtherFieldActive: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  showDurationField: PropTypes.bool.isRequired,
  selectedDuration: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

DurationField.defaultProps = {
  children: null,
  labels: {},
};

export default DurationField;
