import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Icon } from '@bwoty-web/ui-kit';
import { UI_CHEVRON_DOWN, UI_CHEVRON_UP } from '@bwoty-web/ui-kit/icons';

import './formField.scss';

const FormField = (props) => {
  const { label, click, className, value, id, isActive, isOtherFieldActive } = props;

  const formFieldClasses = ClassNames(className, {
    'form-field': true,
    'form-field--active': isActive,
    'form-field--other-active': isOtherFieldActive,
  });

  return (
    <button
      className={formFieldClasses}
      onClick={event => click(event)}
      type="button"
    >
      <Icon
        className="form-field__arrow"
        path={isActive ? UI_CHEVRON_UP : UI_CHEVRON_DOWN}
        size="xxxs"
      />
      <div className="form-field__label">{label}</div>
      <div className="form-field__value">{value}</div>
      <input type="hidden" value={value} id={id} />
    </button>
  );
};

FormField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isOtherFieldActive: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

FormField.defaultProps = {
  label: null,
  isActive: false,
  isOtherFieldActive: false,
  className: null,
};

export default FormField;
