import moment from 'moment';

export const getDurationText = (duration, departureDate, returnDate, labels) => {
  if (!duration) {
    return '';
  }

  if (duration.flexibleDuration) {
    const numberOfDays = moment(returnDate).diff(moment(departureDate), 'days') + 1;
    const label = numberOfDays > 1 ? labels.dayPluralText : labels.daySingularText;
    return `${numberOfDays} ${label}`;
  }

  return duration.name;
};
