import { ActionTypes } from '../constants/actionTypes/bookingstart';

const initialState = {
  packageSectionInitialized: false,
  citySectionInitialized: false,
  cruiseSectionInitialized: false,
  flightSectionInitialized: false,
  hotelSectionInitialized: false,
  bsInitialized: false,
  searchInitialized: false,
};

const bookingstartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT:
      return Object.assign({}, state, {
        selectedProduct: action.selectedProduct,
        siteSettings: action.siteSettings,
      });

    case ActionTypes.INIT_SUCCESS:
      return Object.assign({}, state, {
        labels: action.labels,
        bsInitialized: true,
        selectedProduct: action.selectedProduct,
      });

    case ActionTypes.CHANGE_SELECTED_PRODUCT:
      return Object.assign({}, state, {
        selectedProduct: action.index,
      });

    case ActionTypes.PKG_INIT_SECTION_SUCCESS:
      return Object.assign({}, state, {
        packageSectionInitialized: true,
      });

    case ActionTypes.CITY_INIT_SECTION_SUCCESS:
      return Object.assign({}, state, {
        citySectionInitialized: true,
      });

    case ActionTypes.CRUISE_INIT_SECTION_SUCCESS:
      return Object.assign({}, state, {
        cruiseSectionInitialized: true,
      });
    case ActionTypes.FLIGHT_INIT_SECTION_SUCCESS:
      return Object.assign({}, state, {
        flightSectionInitialized: true,
      });

    case ActionTypes.HOTEL_INIT_SECTION_SUCCESS:
      return Object.assign({}, state, {
        hotelSectionInitialized: true,
      });

    case ActionTypes.SEARCH_INIT:
      return Object.assign({}, state, {
        searchInitialized: true,
      });

    case ActionTypes.SEARCH_DONE:
      return Object.assign({}, state, {
        searchInitialized: false,
      });

    default:
      return state;
  }
};

export default bookingstartReducer;
