import { distanceElementTopToDocumentTop, distanceElementBottomToDocumentTop } from './element';

const mobileWidth = 568;

export const ScrollTo = (endPoint, velocity) => {
  let position = window.pageYOffset;
  if (position >= endPoint) return;

  const scrollInterval = setInterval(() => {
    position += velocity;
    if (position <= endPoint) {
      window.scrollBy(0, velocity);
    } else {
      window.scrollBy(0, endPoint - window.pageYOffset);
      clearInterval(scrollInterval);
    }
  }, 5);
};

export const ScrollToTop = (element) => {
  if (screen.width < mobileWidth) {
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    return;
  }

  const endPoint = distanceElementTopToDocumentTop(element);
  ScrollTo(endPoint, 10);
};

export const ScrollIntoView = (element) => {
  if (screen.width < mobileWidth) {
    return;
  }

  const endPoint = distanceElementBottomToDocumentTop(element) - window.innerHeight;
  ScrollTo(endPoint, 10);
};

export const ScrollContainerTo = (container, scrollTo, instantScroll, speed = 10) => {
  if (instantScroll) {
    container.scrollTop = scrollTo;
    return;
  }

  let velocity = speed;

  // För att kolla om det ska scrolla upp eller ner
  if (container.scrollTop > scrollTo) {
    velocity = -speed;
  }

  const difference = scrollTo - container.scrollTop;
  let distanceScrolled = 0;
  const scrollInterval = setInterval(() => {
    container.scrollTop += velocity;
    distanceScrolled += speed;
    if (Math.abs(distanceScrolled) >= Math.abs(difference)) {
      clearInterval(scrollInterval);
    }
  }, 10);
};

export const ScrollBsIntoViewIfNeeded = () => {
  const bsElement = document.getElementById('bookingstart-app-root');
  const elemTop = bsElement.getBoundingClientRect().top;
  const elemBottom = bsElement.getBoundingClientRect().bottom;
  const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  if (!isVisible) {
    bsElement.scrollIntoView();
  }
};

export const ScrollElementToBottomOfScreen = (element) => {
  const scrollTo = element.getBoundingClientRect().top + window.pageYOffset - (window.innerHeight - element.offsetHeight - 10);
  ScrollContainerTo(document.body, scrollTo, true);
};
