const distanceElementTopToDocumentTop = (element) => {
  const box = element.getBoundingClientRect();
  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const clientTop = docEl.clientTop || body.clientTop || 0;

  return box.top + scrollTop - clientTop;
};

const distanceElementBottomToDocumentTop = (element) => {
  const heightToTop = distanceElementTopToDocumentTop(element);
  const elementHeight = element.clientHeight;
  return heightToTop + elementHeight;
};

export { distanceElementTopToDocumentTop, distanceElementBottomToDocumentTop };
