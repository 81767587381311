import { Component } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

export default class DatePickerHelpMessages extends Component {
  constructor() {
    super();

    this.state = {
      animationDone: true,
      renderInprogressMessage: false,
      renderStandardMessage: true,
    };

    this.messageText = '';
    this.animationDone = this.animationDone.bind(this);
  }

  componentDidMount() {
    this.helpMessage.addEventListener('transitionend', this.animationDone);
  }

  componentWillUnmount() {
    this.helpMessage.removeEventListener('transitionend', this.animationDone);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.dateSelectionInProgress && !nextProps.dateSelectionInProgress) {
      this.setState({ renderInprogressMessage: false, renderStandardMessage: true, animationDone: false });
    }
    if (!this.props.dateSelectionInProgress && nextProps.dateSelectionInProgress) {
      this.setState({ renderInprogressMessage: true, renderStandardMessage: false, animationDone: false });
    }
  }

  animationDone() {
    this.setState({ animationDone: true });
  }

  toggleTextMessage(renderSelectDepartureMessage, renderDateSelectionInProgress) {
    const { labels, isHotelOnly } = this.props;

    if (!renderSelectDepartureMessage && !renderDateSelectionInProgress) {
      return;
    }

    if (renderSelectDepartureMessage) {
      this.messageText = isHotelOnly ? labels.datePickerHelpMessageHotelOnlySelectionInactive : labels.datePickerHelpMessageSelectionInactive;
    } else if (renderDateSelectionInProgress) {
      this.messageText = isHotelOnly ? labels.datePickerHelpMessageHotelOnlySelectionActive : labels.datePickerHelpMessageSelectionActive;
    }
  }

  render() {
    const { labels, selectedDuration, dateSelectionInProgress } = this.props;

    const renderSelectDepartureMessage = selectedDuration.flexibleDuration && this.state.renderStandardMessage && this.state.animationDone;
    const renderDateSelectionInProgress = selectedDuration.flexibleDuration && this.state.renderInprogressMessage && this.state.animationDone;
    let text;

    const helpMessageReturnClasses = ClassNames({
      'bsh-date-picker__help-message': true,
      'bsh-date-picker__help-message--date-selection-inactive': renderSelectDepartureMessage,
      'bsh-date-picker__help-message--date-selection-active': renderDateSelectionInProgress,
    });

    this.toggleTextMessage(renderSelectDepartureMessage, renderDateSelectionInProgress);

    return (
      <div>
        <div className={helpMessageReturnClasses} ref={ref => (this.helpMessage = ref)}>
          {this.messageText}
        </div>
      </div>
    );
  }
}

DatePickerHelpMessages.propTypes = {
  labels: PropTypes.object.isRequired,
  selectedDuration: PropTypes.object,
  setDateSelectionInProgress: PropTypes.func,
  isHotelOnly: PropTypes.bool.isRequired,
};
