import { Component } from 'react'; // eslint-disable-line no-unused-vars
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormFieldLoader from '../formField/FormFieldLoader';
import FormFieldDate from '../formField/FormFieldDate';

export default class DateField extends Component {
  constructor() {
    super();
    this.departureDateDuringSelection = null;
    this.returnDateDuringSelection = null;
  }

  componentWillReceiveProps(nextProps) {
    const selectionNoLongerInProgress = !nextProps.dateSelectionInProgress && this.props.dateSelectionInProgress;
    const retDateHasChanged = nextProps.selectedReturnDate != this.props.selectedReturnDate;
    const depDateHasChanged = nextProps.selectedDepartureDate != this.props.selectedDepartureDate;
    const depDateDuringSelectionHasChanged = nextProps.departureDateDuringSelection != this.props.departureDateDuringSelection;
    const retDateDuringSelectionHasChanged = nextProps.returnDateDuringSelection != this.props.returnDateDuringSelection;

    if (selectionNoLongerInProgress || (nextProps.dateSelectionInProgress && (retDateHasChanged || depDateHasChanged))) {
      this.props.setDateSelectionInProgress(false);
      this.departureDateDuringSelection = null;
      this.returnDateDuringSelection = null;
    } else if (depDateDuringSelectionHasChanged) {
      this.departureDateDuringSelection = nextProps.departureDateDuringSelection;
    } else if (retDateDuringSelectionHasChanged) {
      this.returnDateDuringSelection = nextProps.returnDateDuringSelection;
    }
  }

  render() {
    const {
      className,
      dateSettings,
      labels,
      isLoading,
      isActive,
      isOtherFieldActive,
      toggleOverlay,
      selectedDepartureDate,
      selectedReturnDate,
      selectedDuration,
      hotelLabel,
      datePickerIsActive,
      selectedMonths,
      dateSelectionInProgress,
      departureDateDuringSelection,
      returnDateDuringSelection,
      hideReturnDate,
      isHotelOnly,
    } = this.props;

    const label = hotelLabel || (selectedMonths && selectedMonths.length > 0 ? labels.monthDateFieldLabel : labels.dateInputLabel);

    if (isLoading) {
      return (
        <div className="bs-form__select bs-form__date-select">
          <FormFieldLoader label={label} />
        </div>
      );
    }

    const depDateDuringSelectionFormated = moment(this.departureDateDuringSelection).format(dateSettings.shortDateFormat);
    const depDateFormated = moment(selectedDepartureDate).format(dateSettings.shortDateFormat);
    const returnDateFormated = moment(selectedReturnDate).format(dateSettings.shortDateFormat);

    const showDateSelectIndication = selectedDuration && selectedDuration.flexibleDuration && datePickerIsActive;
    const departureDate = dateSelectionInProgress ? depDateDuringSelectionFormated : depDateFormated;

    let returnDate = null;
    if (dateSelectionInProgress && this.returnDateDuringSelection != null) {
      returnDate = moment(returnDateDuringSelection).format(dateSettings.shortDateFormat);
    } else if (dateSelectionInProgress && this.returnDateDuringSelection == null) {
      returnDate = isHotelOnly ? labels.datepickerHelpmessageHomeHotelOnly : labels.datepickerHelpmessageHome;
    } else {
      returnDate = returnDateFormated;
    }

    const classNames = ClassNames(
      'bs-form__select',
      'bs-form__date-select',
      className,
    );

    return (
      <div className={classNames}>
        <FormFieldDate
          dateSettings={dateSettings}
          label={label}
          click={() => toggleOverlay()}
          isActive={isActive}
          isOtherFieldActive={isOtherFieldActive}
          dateSelectionInProgress={showDateSelectIndication}
          departureDateSelected={departureDateDuringSelection != null}
          departureDate={departureDate}
          returnDate={returnDate}
          hideReturnDate={hideReturnDate}
          selectedMonths={selectedMonths}
        />
        {this.props.children}
      </div>
    );
  }
}

DateField.defaultProps = {
  isHotelOnly: false,
};

DateField.propTypes = {
  labels: PropTypes.object,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  isOtherFieldActive: PropTypes.bool,
  toggleOverlay: PropTypes.func,
  selectedDepartureDate: PropTypes.string,
  selectedReturnDate: PropTypes.string,
  selectedDuration: PropTypes.object,
  selectedMonths: PropTypes.array,
  datePickerIsActive: PropTypes.bool.isRequired,
  dateSelectionInProgress: PropTypes.bool,
  setDateSelectionInProgress: PropTypes.func,
  departureDateDuringSelection: PropTypes.object,
  returnDateDuringSelection: PropTypes.object,
  hideReturnDate: PropTypes.bool,
  hotelLabel: PropTypes.string,
  isHotelOnly: PropTypes.bool,
};
