import { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Icon } from '@bwoty-web/ui-kit';
import {
  UI_ARROW_RIGHT,
  UI_CHEVRON_UP,
  UI_CHEVRON_DOWN,
} from '@bwoty-web/ui-kit/icons';

const capitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

class FormFieldDate extends Component {
  constructor() {
    super();
  }

  toggle(event) {
    const { click } = this.props;
    click(event);
  }

  render() {
    const { departureDate, returnDate, id, dateSelectionInProgress, departureDateSelected, className, label, isActive, isOtherFieldActive, hideReturnDate, selectedMonths } = this.props;

    const renderValue = () => {
      const departureDateClasses = ClassNames({
        'form-field__departure': true,
        'form-field__departure--active': dateSelectionInProgress && !departureDateSelected,
        'form-field__departure--no-return-date': hideReturnDate,
      });

      const returnDateClasses = ClassNames({
        'form-field__return': true,
        'form-field__return--active': dateSelectionInProgress && departureDateSelected,
        'form-field__return--disabled': hideReturnDate,
      });

      return (
        <div>
          <div className="form-field__value">
            <div className={departureDateClasses}>
              <span>{departureDate}</span>
              { !hideReturnDate && (
                <Icon
                  className="form-field__date-separator"
                  path={UI_ARROW_RIGHT}
                  size="xxxs"
                />
              )}
            </div>
            <div className={returnDateClasses}>
              <span>{returnDate}</span>
            </div>
          </div>
          <input type="hidden" value={`${departureDate} ${returnDate}`} id={id} />
        </div>
      );
    };

    const renderMonthValue = () => {
      const monthsString = selectedMonths.map((date) => {
        const month = this.props.dateSettings.months[date.month()];
        return `${capitalize(month)} ${date.year()}`;
      }).join(', ');

      return (
        <div>
          <div className="form-field__value">
            <span>{monthsString}</span>
          </div>
          <input type="hidden" value={monthsString} id={id} />
        </div>
      );
    };

    const formFieldClasses = ClassNames(className, {
      'form-field': true,
      'form-field--date': true,
      'form-field--active': isActive,
      'form-field--other-active': isOtherFieldActive,
    });

    return (
      <button
        className={formFieldClasses}
        onClick={event => this.toggle(event)}
        type="button"
      >
        <Icon
          className="form-field__arrow"
          path={isActive ? UI_CHEVRON_UP : UI_CHEVRON_DOWN}
          size="xxxs"
        />
        <label className="form-field__label">{label}</label>
        {selectedMonths && selectedMonths.length > 0 ? renderMonthValue() : renderValue()}
      </button>
    );
  }
}

FormFieldDate.propTypes = {
  label: PropTypes.string,
  departureDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string,
  id: PropTypes.string,
  click: PropTypes.func.isRequired,
  className: PropTypes.string,
  dateSelectionInProgress: PropTypes.bool,
  departureDateSelected: PropTypes.bool,
  isActive: PropTypes.bool,
  hideReturnDate: PropTypes.bool,
  selectedMonths: PropTypes.array,
};

export default FormFieldDate;
