import moment from 'moment';
import { getQueryKeyValue } from '../utils/url/queryHelper';
import QueryKeys from '../utils/url/queryKeys';
import { getApiUrls, getSearchUrlAndStoreSearch } from './api';
import { getRoomDistributionString, getTotalNumberOfPax } from '../utils/pax/helper';
import { fetchJson } from '../utils/fetch';

export const getInitState = async (preSelectedSettings) => {
  const { BOOKING_START } = QueryKeys;
  const resortId = getQueryKeyValue(BOOKING_START.resortId);
  const areaId = getQueryKeyValue(BOOKING_START.areaId);
  const areaResortId = resortId !== '' && resortId !== '0' ? resortId : areaId;
  const roomDistribution = getQueryKeyValue(BOOKING_START.roomDistribution);
  const roomAges = typeof roomDistribution === 'string' && roomDistribution.startsWith('|') ? roomDistribution.substr(1) : roomDistribution;
  const queryUnits = getQueryKeyValue(BOOKING_START.units);
  const everyoneInSameRoom = queryUnits === '1';
  const categoryId = getQueryKeyValue(BOOKING_START.categoryId);
  const searchCharter = categoryId === '1' || categoryId === '2' || categoryId === '4';

  const apiUrls = await getApiUrls();

  const url = apiUrls.cityInitStateUrl
    .replace('{departureId}', getQueryKeyValue(BOOKING_START.departureId) || preSelectedSettings.departureId || '-')
    .replace('{areaResortId}', areaResortId || preSelectedSettings.resortId || '-')
    .replace('{duration}', getQueryKeyValue(BOOKING_START.duration) || '-')
    .replace('{departureDate}', getQueryKeyValue(BOOKING_START.departureDate) || '-')
    .replace('{returnDate}', getQueryKeyValue(BOOKING_START.returnDate) || '-')
    .replace('{checkInDate}', getQueryKeyValue(BOOKING_START.checkInDate) || '-')
    .replace('{checkOutDate}', getQueryKeyValue(BOOKING_START.checkOutDate) || '-')
    .replace('{roomAges}', roomAges || '-')
    .replace('{sameRoom}', queryUnits !== '' ? everyoneInSameRoom : '-')
    .replace('{searchCharter}', searchCharter || '-');

  const initState = await fetchJson(url);

  // Parse departureMonths from string to moment
  if (initState != null && initState.departureMonths != null && initState.departureMonths.length > 0) {
    initState.departureMonths = initState.departureMonths.map(month => moment(month));
  }

  return initState;
};

export const getResorts = async (departureAirportId) => {
  const apiUrls = await getApiUrls();
  const url = apiUrls.cityResortsUrl
    .replace('{departureId}', departureAirportId);

  const resorts = await fetchJson(url);

  return resorts;
};

export const searchDestinations = async (departureId, searchTerm) => {
  const trimmedSearchTerm = searchTerm.replace(new RegExp('[0-9/]', 'g'), '').trim();
  const theSearchTerm = (trimmedSearchTerm === '') ? '-1' : trimmedSearchTerm;
  const apiUrls = await getApiUrls();

  const url = apiUrls.citySearchDestinationsUrl
    .replace('{departureId}', departureId)
    .replace('{searchTerm}', theSearchTerm);

  const destinations = await fetchJson(url);

  return destinations;
};

export const getDurations = async (departureAirportId, resortId) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.cityItemDurationsUrl
    .replace('{departureId}', departureAirportId)
    .replace('{itemId}', resortId);

  const durations = await fetchJson(url);

  return durations;
};

export const getPriceCalendar = async(departureAirportId, resortId, departureDate, duration, directFlight) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.packageIndependentPriceCalendarUrl
  .replace('{departureId}', departureAirportId)
  .replace('{areaId}', resortId)
  .replace('{resortId}', resortId)
  .replace('{hotelPartId}', '0')
  .replace('{departureDate}', departureDate)
  .replace('{duration}', duration)
  .replace('{directFlight}', directFlight);
  
  const priceCalendar = fetchJson(url);

  return priceCalendar;
};

export const getCharterDepartureDates = async (departureAirportId, resortId, duration) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.cityItemCharterDatesUrl
    .replace('{departureId}', departureAirportId)
    .replace('{itemId}', resortId)
    .replace('{duration}', duration);

  const departureDates = await fetchJson(url);

  return departureDates;
};

export const getIndependentDepartureDates = async (departureAirportId, resortId, duration, flexible) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.cityItemIndependentDatesUrl
    .replace('{departureId}', departureAirportId)
    .replace('{itemId}', resortId)
    .replace('{duration}', duration)
    .replace('{duration}', duration)
    .replace('{flexible}', flexible);

  const departureDates = await fetchJson(url);

  return departureDates;
};

export const getExtendedSearchOptions = async (baseUrl, siteId, departureAirportId, resortId, areaId) => {
  const areaIdOrEmptyString = areaId || areaId === 0 ? `&areaId=${areaId}` : '';
  const url = `${baseUrl}/independentweb-extended-search/options/${siteId}?departureId=${departureAirportId}&resortId=${resortId}${areaIdOrEmptyString}`;
  const extendedSearchOptions = await fetchJson(url);
  
  return {
    ...extendedSearchOptions,
    selectedCabinClass: extendedSearchOptions.displayCabinClasses?.includes('Economy')
      ? 'Economy'
      : null,
  };
};

export const getSearchUrl = async (citySection) => {
  const {
    selectedDepartureAirport,
    selectedResort,
    selectedDuration,
    selectedDepartureDate,
    selectedReturnDate,
    selectedMonths,
    isEveryoneInSameRoomChecked,
    roomDistribution,
    siteSettings,
    selectedOffer,
    showPriceCalendar,
    showDirectFlights,
    searchDirectOnly,
    searchLuggageIncluded,
    selectedCabinClass,
  } = citySection;

  const { siteId, apiUrl } = siteSettings;


  const depId = selectedDepartureAirport.itemId;
  const destId = '-1';
  const ctryId = selectedResort.countryId;
  let areaId;
  let resId;
  if (selectedResort.isArea) {
    areaId = selectedResort.itemId;
    resId = '-1';
  } else {
    areaId = '-1';
    resId = selectedResort.itemId;
  }
  let dur = selectedDuration.defaultDuration;
  if (selectedDuration.flexibleDuration) {
    dur = '-1';
  }
  const depDate = moment(selectedDepartureDate).format('YYYYMMDD');
  const retDate = moment(selectedReturnDate).format('YYYYMMDD');

  let depMonths = '-1';
  if (selectedMonths && selectedMonths.length > 0) {
    depMonths = selectedMonths.map(month => moment(month).format('YYYYMM')).join(',');
  }

  const roomAges = getRoomDistributionString(roomDistribution, selectedDuration.bookingType);
  const sameRoom = isEveryoneInSameRoomChecked;
  let searchCharter;
  let categoryId;
  if (selectedDuration.bookingType === 'independent') {
    searchCharter = 'false';
    categoryId = '3';
  } else if (selectedDuration.bookingType === 'charter') {
    searchCharter = 'true';
    categoryId = '2';
  }

  const sectionData = {
    depId,
    destId,
    ctryId,
    areaId,
    resId,
    dur,
    depDate,
    retDate,
    depMonths,
    roomAges,
    sameRoom,
    searchCharter,
    categoryId,
    categoryFilterId: '17',
  };

  const searchUrls = await getSearchUrlAndStoreSearch(sectionData);

  let url;

  const isHotelFinderSearch = selectedMonths && selectedMonths.length > 0 && getTotalNumberOfPax(roomDistribution) < 7;
  if (isHotelFinderSearch) {
    url = searchUrls.hotelFinder
      .replace('{depId}', depId)
      .replace('{ctryId}', ctryId)
      .replace('{depMonths}', depMonths)
      .replace('{duration}', dur)
      .replace('{roomAges}', roomAges)
      .replace('{nofUnits}', sameRoom === true ? '1' : '0')
      .replace('{areaId}', areaId)
      .replace('{resId}', resId);
    return url;
  }

  if (citySection.selectedDuration.bookingType === 'independent') {
    url = searchUrls.cityIndependent;
  } else if (citySection.selectedDuration.bookingType === 'charter') {
    url = searchUrls.cityCharter;
  }
  url = url
    .replace('{depId}', depId)
    .replace('{ctryId}', ctryId)
    .replace('{areaId}', areaId)
    .replace('{resId}', resId)
    .replace('{depDate}', depDate)
    .replace('{duration}', dur)
    .replace('{retDate}', retDate)
    .replace('{roomAges}', roomAges)
    .replace('{nofUnits}', citySection.isEveryoneInSameRoomChecked === true ? '1' : '0');


  if (showPriceCalendar && selectedOffer) {
    url += `&BestPrice=true&SelectedHotelCode=${selectedOffer.hotelCode}&sortKey=LOWEST_PRICE&showPriceCalendar=true`;
    url += `${showDirectFlights ? '&showDirectFlights=true' : ''}`;    
  } else {
    // Extended search options if no selectedOffer
    url +=
      `${searchLuggageIncluded ? '&IncludeBaggage=true' : ''}` +
      `${selectedCabinClass ? `&CabinClass=${selectedCabinClass}` : ''}`;
  }

  if (searchDirectOnly) {
    url += '&DirectFlight=true'
  }

  return url;
};
