import moment from 'moment';
import { getQueryKeyValue } from '../utils/url/queryHelper';
import QueryKeys from '../utils/url/queryKeys';
import { getApiUrls, getSearchUrlAndStoreSearch } from './api';
import { getRoomDistributionString, getTotalNumberOfPax } from '../utils/pax/helper';
import { fetchJson } from '../utils/fetch';

export const getInitState = async (preSelectedSettings) => {
  const { BOOKING_START } = QueryKeys;
  const resortId = getQueryKeyValue(BOOKING_START.resortId);
  const areaId = getQueryKeyValue(BOOKING_START.areaId);
  const areaResortId = resortId !== '' && resortId !== '0' ? resortId : areaId;
  const roomDistribution = getQueryKeyValue(BOOKING_START.roomDistribution);
  const roomAges = typeof roomDistribution === 'string' && roomDistribution.startsWith('|') ? roomDistribution.substr(1) : roomDistribution;
  const queryUnits = getQueryKeyValue(BOOKING_START.units);
  const everyoneInSameRoom = queryUnits === '1';
  const categoryId = getQueryKeyValue(BOOKING_START.categoryId);
  const searchCharter = categoryId === '1' || categoryId === '2' || categoryId === '4';

  const apiUrls = await getApiUrls();

  const url = apiUrls.packageInitStateUrl
    .replace('{departureId}', getQueryKeyValue(BOOKING_START.departureId) || preSelectedSettings.departureId || '-')
    .replace('{countryId}', getQueryKeyValue(BOOKING_START.countryId) || preSelectedSettings.countryId || '-')
    .replace('{areaResortId}', areaResortId || preSelectedSettings.resortId || '-')
    .replace('{duration}', getQueryKeyValue(BOOKING_START.duration) || '-')
    .replace('{departureDate}', getQueryKeyValue(BOOKING_START.departureDate) || '-')
    .replace('{returnDate}', getQueryKeyValue(BOOKING_START.returnDate) || '-')
    .replace('{checkInDate}', getQueryKeyValue(BOOKING_START.checkInDate) || '-')
    .replace('{checkOutDate}', getQueryKeyValue(BOOKING_START.checkOutDate) || '-')
    .replace('{roomAges}', roomAges || '-')
    .replace('{sameRoom}', queryUnits !== '' ? everyoneInSameRoom : '-')
    .replace('{searchCharter}', searchCharter || '-');

  const initState = await fetchJson(url);

  // Parse departureMonths from string to moment
  if (initState != null && initState.departureMonths != null && initState.departureMonths.length > 0) {
    initState.departureMonths = initState.departureMonths.map(month => moment(month));
  }

  return initState;
};

export const searchDestinations = async (departureId, searchTerm) => {
  const trimmedSearchTerm = searchTerm.replace(new RegExp('[0-9/]', 'g'), '').trim();
  const theSearchTerm = (trimmedSearchTerm === '') ? '-1' : trimmedSearchTerm;

  const apiUrls = await getApiUrls();

  const url = apiUrls.packageSearchDestinationsUrl
    .replace('{departureId}', departureId)
    .replace('{searchTerm}', theSearchTerm);

  const destinations = await fetchJson(url);

  return destinations;
};

export const getCountries = async (departureAirportId) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.packageCountriesUrl
    .replace('{departureId}', departureAirportId);

  const countries = await fetchJson(url);

  return countries;
};

export const getResorts = async (departureAirportId, countryId) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.packageResortsUrl
    .replace('{departureId}', departureAirportId)
    .replace('{countryId}', countryId);

  const resorts = await fetchJson(url);

  return resorts;
};

export const getDurations = async (departureAirportId, countryId, resortId) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.packageItemDurationsUrl
    .replace('{departureId}', departureAirportId)
    .replace('{countryId}', countryId)
    .replace('{itemId}', resortId);

  const durationsData = await fetchJson(url);

  return durationsData;
};

export const getPriceCalendar = async(departureAirportId, areaId, resortId, hotelPartId, departureDate, duration, directFlight) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.packageIndependentPriceCalendarUrl
  .replace('{departureId}', departureAirportId)
  .replace('{areaId}', areaId)
  .replace('{resortId}', resortId)
  .replace('{hotelPartId}', '0')
  .replace('{departureDate}', departureDate)
  .replace('{duration}', duration)
  .replace('{directFlight}', directFlight);
  
  const priceCalendar = fetchJson(url);

  return priceCalendar;
};

export const getCharterDepartureDates = async (departureAirportId, countryId, resortId, duration) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.packageCharterDepartureDatesUrl
    .replace('{departureId}', departureAirportId)
    .replace('{countryId}', countryId)
    .replace('{itemId}', resortId)
    .replace('{duration}', duration);

  const departureDates = await fetchJson(url);

  return departureDates;
};

export const getIndependentDepartureDates = async (departureAirportId, countryId, resortId, duration, flexible) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.packageIndependentDepartureDatesUrl
    .replace('{departureId}', departureAirportId)
    .replace('{countryId}', countryId)
    .replace('{itemId}', resortId)
    .replace('{duration}', duration)
    .replace('{flexible}', flexible);

  const departureDates = fetchJson(url);

  return departureDates;
};

export const getExtendedSearchOptions = async (baseUrl, siteId, departureAirportId, resortId, areaId) => {
  const areaIdOrEmptyString = areaId || areaId === 0 ? `&areaId=${areaId}` : '';
  const url = `${baseUrl}/independentweb-extended-search/options/${siteId}?departureId=${departureAirportId}&resortId=${resortId}${areaIdOrEmptyString}`;
  const extendedSearchOptions = await fetchJson(url);

  return {
    ...extendedSearchOptions,
    selectedCabinClass: extendedSearchOptions.displayCabinClasses?.includes('Economy')
    ? 'Economy'
    : null,
  };
};

export const getSearchUrl = async (packageSection) => {
  const {
    selectedDepartureAirport,
    selectedDestination,
    selectedDuration,
    selectedDepartureDate,
    selectedReturnDate,
    selectedOffer,
    selectedMonths,
    isEveryoneInSameRoomChecked,
    roomDistribution,
    showPriceCalendar,
    showDirectFlights,
    searchDirectOnly,
    searchLuggageIncluded,
    selectedCabinClass,
  } = packageSection;

  const depDate = moment(selectedDepartureDate).format('YYYYMMDD');
  const retDate = moment(selectedReturnDate).format('YYYYMMDD');

  let depMonths = '-1';
  if (selectedMonths && selectedMonths.length > 0) {
    depMonths = selectedMonths
      .map((month) => moment(month).format('YYYYMM'))
      .join(',');
  }

  let dur = selectedDuration.defaultDuration;

  if (selectedDuration.flexibleDuration) {
    dur = '-1';
  }

  const depId = selectedDepartureAirport.itemId;
  const destId = '-1';
  const ctryId = selectedDestination.countryId;
  let areaId;
  let resId;
  if (selectedDestination.isArea) {
    areaId = selectedDestination.itemId;
    resId = '0';
  } else {
    resId = selectedDestination.itemId;
    areaId = '0';
  }

  const roomAges = getRoomDistributionString(
    roomDistribution,
    selectedDuration.bookingType,
  );
  const sameRoom = isEveryoneInSameRoomChecked;
  let searchCharter;
  let categoryId;
  if (selectedDuration.bookingType === 'independent') {
    searchCharter = 'false';
    categoryId = '3';
  } else if (selectedDuration.bookingType === 'charter') {
    searchCharter = 'true';
    categoryId = '2';
  }
  const categoryFilterId = '-';

  const sectionData = {
    depId,
    destId,
    ctryId,
    areaId,
    resId,
    dur,
    depDate,
    retDate,
    depMonths,
    roomAges,
    sameRoom,
    searchCharter,
    categoryId,
    categoryFilterId,
  };

  const searchUrls = await getSearchUrlAndStoreSearch(sectionData);

  let url;

  const isHotelFinderSearch =
    selectedMonths &&
    selectedMonths.length > 0 &&
    getTotalNumberOfPax(roomDistribution) < 7;

  if (isHotelFinderSearch) {
    url = searchUrls.hotelFinder
      .replace('{depId}', depId)
      .replace('{ctryId}', ctryId)
      .replace('{depMonths}', depMonths)
      .replace('{duration}', dur)
      .replace('{roomAges}', roomAges)
      .replace('{nofUnits}', sameRoom === true ? '1' : '0')
      .replace('{areaId}', areaId)
      .replace('{resId}', resId);
    return url;
  }

  if (selectedDuration.bookingType === 'independent') {
    url = searchUrls.packageIndependent;
  } else if (selectedDuration.bookingType === 'charter') {
    url = searchUrls.packageCharter;
  }

  url = url
    .replace('{depId}', depId)
    .replace('{ctryId}', ctryId)
    .replace('{areaId}', areaId)
    .replace('{resId}', resId)
    .replace('{depDate}', depDate)
    .replace('{duration}', dur)
    .replace('{retDate}', retDate)
    .replace('{roomAges}', roomAges)
    .replace('{nofUnits}', isEveryoneInSameRoomChecked === true ? '1' : '0');

  if (showPriceCalendar && selectedOffer) {
    url += `&BestPrice=true&SelectedHotelCode=${selectedOffer.hotelCode}&sortKey=LOWEST_PRICE&showPriceCalendar=true`;
    url += `${showDirectFlights ? '&showDirectFlights=true' : ''}`;
  } else {
    // Extended search options if no selectedOffer
    url +=
      `${searchLuggageIncluded ? '&IncludeBaggage=true' : ''}` +
      `${selectedCabinClass ? `&CabinClass=${selectedCabinClass}` : ''}`;
  }

  if (searchDirectOnly) {
    url += '&FastestFlight=true';
  }

  return url;
};
