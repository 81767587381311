/* eslint-disable no-restricted-globals */
export const isValidAge = (value) => {
  return !isNaN(value)
    && parseInt(Number(value), 10) === value
    && !isNaN(parseInt(value, 10));
};

export const validNumberOfInfants = (roomDistribution) => {
  const numberOfAdults = [...roomDistribution].reduce((a, b) => {
    return a + b.numberOfAdults;
  }, 0);

  const numberOfInfants = [...roomDistribution].reduce((a, b) => {
    const infantsInRoom = b.childAges.filter((age, index) => {
      return (age <= 1) && (index < b.numberOfChildren);
    }).length;
    return a + infantsInRoom;
  }, 0);

  return numberOfAdults >= numberOfInfants;
};

export const validAges = (roomDistribution) => {
  let isValid = true;

  [...roomDistribution].forEach((room) => {
    const { numberOfChildren, childAges } = room;
    for (let childIndex = 0; childIndex < numberOfChildren; childIndex += 1) {
      const age = parseInt(childAges[childIndex], 10);
      if (age === -1 || !isValidAge(age)) {
        isValid = false;
      }
    }
  });

  return isValid;
};

export const validNumberOfAdultsInRoom = (roomDistribution) => {
  let isValid = true;

  [...roomDistribution].forEach((room) => {
    const { numberOfAdults, numberOfChildren } = room;
    if (numberOfChildren > 0 && numberOfAdults < 1) {
      isValid = false;
    }
  });

  return isValid;
};

export const validRoomDistribution = (roomDistribution) => {
  return (
    validAges(roomDistribution)
    && validNumberOfInfants(roomDistribution)
    && validNumberOfAdultsInRoom(roomDistribution)
  );
};
