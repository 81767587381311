import queryKeys from '../../utils/url/queryKeys';
import { getQueryKeyValue } from '../../utils/url/queryHelper';
import { ActionTypes } from '../constants/actionTypes/bookingstart';

const packageReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PKG_INIT_SECTION:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.PKG_INIT_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        departureAirportList: action.data.departures,
        selectedDepartureAirport: action.data.selectedDeparture,
        countryList: action.data.countries,
        selectedDestination: action.data.selectedResort,
        durationList: action.data.durations,
        departureDates: action.data.departureDates,
        returnDates: action.data.returnDates,
        selectedDuration: action.data.selectedDuration,
        selectedDepartureDate: action.data.selectedDepartureDate,
        selectedReturnDate: action.data.selectedReturnDate,
        departureMonths: action.data.departureMonths || [],
        isEveryoneInSameRoomChecked: action.data.everyOneInSameRoom,
        selectedNumberOfRooms: action.data.selectedNumberOfRooms,
        searchHistory: action.data.searchHistory,
        rooms: action.data.rooms,
        searchInformation: action.data.searchInformation,
        sectionName: 'package',
        showPriceCalendar: action.showPriceCalendar,
        showDirectFlights: action.showDirectFlights,
        priceCalendarDurationOptions: action.data.options,
        priceCalendarData: {},
        previousDuration: null,
        showDirectOnly: false,
        searchDirectOnly: false,
        showLuggageIncluded: false,
        searchLuggageIncluded: false,
        showCabinClasses: null,
        selectedCabinClass: null,
        showDateLayer: false,
      };

    case ActionTypes.PKG_CHANGE_DEPARTURE_AIRPORT:
      return {
        ...state,
        selectedDepartureAirport: action.airport,
        departureMonths: [],
      };

    case ActionTypes.PKG_SEARCH_DEPARTURE_AIRPORT_SUCCESS:
      return {
        ...state,
        searchResultsDepartureAirport: action.searchResults,
      };

    case ActionTypes.PKG_SEARCH_DESTINATIONS_SUCCESS:
      return {
        ...state,
        searchResultsDestination: action.searchResults,
      };

    case ActionTypes.PKG_GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countryList: action.countries,
        selectedDestination: action.selectedDestination,
      };

    case ActionTypes.PKG_CHANGE_DESTINATION:
      return {
        ...state,
        selectedDestination: action.destination,
        departureMonths: [],
      };

    case ActionTypes.PKG_GET_DURATIONS_SUCCESS:
      return {
        ...state,
        durationList: action.durations,
        selectedDuration: action.selectedDuration,
        priceCalendarDurationOptions: action.options,
      };

    case ActionTypes.PKG_CHANGE_DURATION:
      return {
        ...state,
        selectedDuration: action.duration,
        departureMonths: [],
      };

    case ActionTypes.PKG_GET_DEPARTURE_DATES_SUCCESS:
      return {
        ...state,
        departureDates: action.departureDates,
        selectedDepartureDate: action.selectedDepartureDate,
        selectedReturnDate: action.selectedReturnDate,
      };

    case ActionTypes.PKG_CHANGE_DEPARTURE_DATE:
      return {
        ...state,
        selectedDepartureDate: action.date,
        selectedReturnDate: action.selectedReturnDate,
        selectedOffer: action.selectedOffer,
      };

    case ActionTypes.PKG_CHANGE_DATES:
      return {
        ...state,
        selectedDepartureDate: action.departureDate,
        selectedReturnDate: action.returnDate,
      };

    case ActionTypes.PKG_CHANGE_ROOM_DISTRIBUTION:
      return {
        ...state,
        rooms: action.roomDistribution,
        selectedNumberOfRooms: action.selectedNumberOfRooms,
        isEveryoneInSameRoomChecked: action.isSameRoom,
      };

    case ActionTypes.PKG_CHANGE_DEPARTURE_MONTHS:
      return {
        ...state,
        departureMonths: action.departureMonths,
        selectedDepartureDate: action.departureDate,
        selectedReturnDate: action.returnDate,
      };

    case ActionTypes.PKG_SET_SHOW_PRICE_CALENDAR:
      return {
        ...state,
        showPriceCalendar: action.showPriceCalendar,
      };

    case ActionTypes.PKG_SET_SHOW_DIRECT_FLIGHTS:
      return {
        ...state,
        showDirectFlights: action.showDirectFlights,
      };

    case ActionTypes.PKG_GET_PRICE_CALENDAR_DATA_SUCCESS:
      return {
        ...state,
        priceCalendarData: {
          ...action.priceCalendarData,
        },
      };

    case ActionTypes.PKG_SET_PREVIOUS_DURATION:
      return {
        ...state,
        previousDuration: action.previousDuration,
      };

    case ActionTypes.PKG_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS: {
      const { options } = action;
      const cabinClassFromUrl = getQueryKeyValue(
        queryKeys.BOOKING_START.cabinClass,
      );

      const cabinClassAllowed =
        options?.displayCabinClasses?.constructor === Array &&
        options?.displayCabinClasses?.includes(cabinClassFromUrl);

      const selectedCabinClass =
        (cabinClassAllowed && cabinClassFromUrl) || options?.selectedCabinClass;

      return {
        ...state,
        showDirectOnly: options?.displayDirectOnly,
        showLuggageIncluded: options?.displayBaggage,
        showCabinClasses: options?.displayCabinClasses,
        selectedCabinClass,
        searchLuggageIncluded: false,
        searchDirectOnly: false,
      };
    }

    case ActionTypes.PKG_SET_CABINCLASS:
      return {
        ...state,
        selectedCabinClass: action.cabinclass,
      };

    case ActionTypes.PKG_SET_SEARCH_DIRECT_ONLY:
      return {
        ...state,
        searchDirectOnly: action.searchDirectOnly,
      };

    case ActionTypes.PKG_SET_SEARCH_LUGGAGE_INCLUDED:
      return {
        ...state,
        searchLuggageIncluded: action.searchLuggageIncluded,
      };

    case ActionTypes.PKG_SET_SHOW_DATE_LAYER:
      return {
        ...state,
        showDateLayer: action.showDateLayer,
      };

    default:
      return state;
  }
};

export default packageReducer;
