import { Component } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { isLargeScreen } from '../../utils/device';
import DestinationSearchField from './DestinationSearchField';
import DestinationSearchResult from './DestinationSearchResult';

import './destinationSearch.scss';

export default class DestinationSearch extends Component {
  constructor() {
    super();
    this.state = {
      isInFocus: false,
      searchString: '',
    };
    this.hideIfClickOutside = this.hideIfClickOutside.bind(this);
  }

  componentDidMount() {
    this.addClickOutsideListener();
  }

  componentWillUnmount() {
    this.removeClickOutsideListener();
  }

  focus() {
    this.setState({ isInFocus: true });
  }

  blur() {
    this.setState({ isInFocus: false });
  }

  cancelSearch() {
    this.onTextChange('');
    this.blur();
  }

  addClickOutsideListener() {
    if (isLargeScreen()) {
      document.addEventListener('click', this.hideIfClickOutside);
    }
  }

  removeClickOutsideListener() {
    if (isLargeScreen()) {
      document.removeEventListener('click', this.hideIfClickOutside);
    }
  }

  hideIfClickOutside(event) {
    if (this.refs.SearchContainer && !this.refs.SearchContainer.contains(event.target)) {
      this.blur();
    }
  }

  onTextChange(searchString) {
    clearTimeout(this.timeout);
    const { onTextSearch } = this.props;
    this.setState({ searchString });
    this.timeout = setTimeout(
      () => {
        onTextSearch(searchString);
      },
      100
    );
  }

  render() {
    const { searchResult, selectDestination, labels } = this.props;
    const { isInFocus, searchString } = this.state;
    const showSearchResult = searchString.length > 0 && searchResult;

    const searchContainerClasses = ClassNames({
      'destination-search-container': true,
      'destination-search-container--active': isInFocus,
    });

    return (
      <div className={searchContainerClasses} ref="SearchContainer">
        <DestinationSearchField
          onFocus={() => this.focus()}
          onChange={searchString => this.onTextChange(searchString)}
          isInFocus={isInFocus}
          cancelSearch={() => this.cancelSearch()}
          searchString={searchString}
          labels={labels}
        />

        {showSearchResult ? (
          <DestinationSearchResult
            searchResult={searchResult}
            selectDestination={selectDestination}
            labels={labels}
            searchString={searchString}
            cancelSearch={() => this.cancelSearch()}
          />
        ) : null}
      </div>
    );
  }
}

DestinationSearch.propTypes = {
  onTextSearch: PropTypes.func.isRequired,
  searchResult: PropTypes.object,
  selectDestination: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
};
