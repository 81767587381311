import { tryCatch } from '@bwoty-web/utils';

export const fetchJson = async (uri) => {
  const [error, json] = await tryCatch(async () => {
    const response = await fetch(uri);
    return response.json();
  });

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return json.result;
};

export const fetchJsonByProxy = async (uri, baseUrl) => {
  const url = `${baseUrl}/api/proxy?url=${encodeURIComponent(uri.toString())}`;
  const response = await fetch(url);
  return response.json();
};
