import { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './durationSelect.scss';

export default class DurationSelect extends Component {
  constructor() {
    super();
  }

  isCharterDuration(duration) {
    return duration.bookingType == 'charter';
  }

  isIndependentDuration(duration) {
    return duration.bookingType == 'independent';
  }

  renderDurationItems(durations) {
    const { changeDuration, selectedDuration } = this.props;

    return durations.map((duration, index) => {
      let isSelected = false;
      if (duration.flexibleDuration) {
        isSelected = selectedDuration.flexibleDuration;
      } else {
        isSelected = duration.defaultDuration === selectedDuration.defaultDuration && duration.bookingType === selectedDuration.bookingType && !selectedDuration.flexibleDuration;
      }

      const durationItemClasses = ClassNames({
        'duration-select__item': true,
        'duration-select__item--selected': isSelected,
      });

      return (
        <button
          className={durationItemClasses}
          key={index}
          onClick={() => changeDuration(duration)}
          type="button"
        >
          {duration.name}
        </button>
      );
    });
  }

  renderCharterDuartions() {
    const { durationList, labels, isFlightDuration } = this.props;
    const charterDurations = durationList.filter(this.isCharterDuration);

    if (charterDurations.length < 1) {
      return null;
    }

    const categoryHeader = isFlightDuration ? labels.durationCategoryFlightCharter : labels.durationCategoryCharter;

    return (
      <div className="duration-select__category">
        <div className="duration-select__category-header" data-wdio-header-type="charter">
          {categoryHeader}
        </div>
        {this.renderDurationItems(charterDurations)}
      </div>
    );
  }

  renderIndependentDurations() {
    const { durationList, labels, isFlightDuration } = this.props;
    const independentDurations = durationList.filter(this.isIndependentDuration);

    if (independentDurations.length < 1) {
      return null;
    }

    const categoryHeader = isFlightDuration ? labels.durationCategoryFlightInd : labels.durationCategoryInd;

    return (
      <div className="duration-select__category">
        <div className="duration-select__category-header" data-wdio-header-type="independent">
          {categoryHeader}
        </div>
        {this.renderDurationItems(independentDurations)}
      </div>
    );
  }

  render() {
    return (
      <div className="duration-select">
        {this.renderCharterDuartions()}
        {this.renderIndependentDurations()}
      </div>
    );
  }
}

DurationSelect.propTypes = {
  durationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDuration: PropTypes.shape({
    defaultDuration: PropTypes.number.isRequired,
    flexibleDuration: PropTypes.bool.isRequired,
  }).isRequired,
  changeDuration: PropTypes.func.isRequired,
  labels: PropTypes.shape().isRequired,
  isFlightDuration: PropTypes.bool,
};

DurationSelect.defaultProps = {
  isFlightDuration: false,
};
