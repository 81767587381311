import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Icon } from '@bwoty-web/ui-kit';
import { Component } from 'react';
import { UI_CHEVRON_DOWN, UI_CHEVRON_UP } from '@bwoty-web/ui-kit/icons';

import DestinationSearch from './DestinationSearch';

import './destinationSelect.scss';

const NUMBER_OF_COLUMNS = 3;

export default class DestinationSelect extends Component {
  constructor() {
    super();

    this.state = {
      expandedCountries: [],
      expandedAreas: [],
    };
  }

  componentDidMount() {
    const { selectedDestination, countries } = this.props;
    let selectedCountry,
      selectedArea;

    countries.forEach((country) => {
      if (selectedDestination.countryId == country.itemId) {
        selectedCountry = country;
      }
    });

    if (selectedDestination.itemId != -1) {
      const areaResorts = selectedCountry.areaResorts || selectedCountry.destinationAirports;
      areaResorts.forEach((item) => {
        if (item.isArea) {
          for (let i = 0; i < item.resorts.length; i++) {
            const isSelected = selectedDestination.itemId == item.itemId;
            if (item.resorts[i].itemId == selectedDestination.itemId || isSelected) {
              selectedArea = item;
            }
          }
        }
      });
    }

    this.setState({
      expandedCountries: selectedCountry ? [selectedCountry.itemId] : [],
      expandedAreas: selectedArea ? [selectedArea.itemId] : [],
    });
  }

  selectDestination(item) {
    this.props.setDestination(item);
  }

  selectDestinationFreeText(item) {
    this.props.setDestination(item);
  }

  toggleExpandedCountry(id) {
    const { expandedCountries } = this.state;
    const expandedCountriesNew = expandedCountries;
    if (expandedCountries.indexOf(id) > -1) {
      expandedCountriesNew.splice(expandedCountriesNew.indexOf(id), 1);
      this.setState({ expandedCountries: expandedCountriesNew });
    } else {
      expandedCountriesNew.push(id);
      this.setState({
        expandedCountries: expandedCountriesNew,
      });
    }
  }

  toggleExpandedArea(event, id) {
    const { expandedAreas } = this.state;
    const expandedAreasNew = expandedAreas;
    if (expandedAreas.indexOf(id) > -1) {
      expandedAreasNew.splice(expandedAreasNew.indexOf(id), 1);
      this.setState({ expandedAreas: expandedAreasNew });
    } else {
      expandedAreasNew.push(id);
      this.setState({
        expandedArea: expandedAreasNew,
      });
    }
    event.stopPropagation();
  }

  renderResort(resort, index, parent = null) {
    const { selectedDestination } = this.props;
    const item = resort.itemId == -1 && parent ? parent : resort;
    let isSelected = false;

    // Check if all under area is selected
    if (parent != null && parent.isArea && parent.itemId == selectedDestination.itemId && resort.itemId == -1) {
      isSelected = true;
    }

    // Check if resort is selected
    if (resort.itemId != -1 && resort.itemId == selectedDestination.itemId) {
      isSelected = true;
    }

    if (resort.itemId == selectedDestination.itemId && parent == null && selectedDestination.countryId === resort.countryId) {
      isSelected = true;
    }

    const resortClasses = ClassNames({
      'destination-select__resort': true,
      'destination-select__resort--selected': isSelected,
    });

    return (
      <button
        className={resortClasses}
        key={index}
        onClick={() => this.selectDestination(item)}
        type="button"
      >
        {resort.name}
      </button>
    );
  }

  renderArea(area, index) {
    const { expandedAreas } = this.state;
    const resortsHtml = [];

    for (let i = 0; i < area.resorts.length; i++) {
      resortsHtml.push(this.renderResort(area.resorts[i], i, area));
    }

    const isExpandable = area.resorts.length > 0;
    const isExpanded = expandedAreas.indexOf(area.itemId) > -1;

    const areaClasses = ClassNames({
      'destination-select__area': true,
      'destination-select__area--expandable': isExpandable,
      'destination-select__area--expanded': isExpanded,
    });

    const iconPath = isExpanded ? UI_CHEVRON_UP : UI_CHEVRON_DOWN;

    return (
      <button
        className={areaClasses}
        key={index}
        onClick={event => this.toggleExpandedArea(event, area.itemId)}
        type="button"
      >
        <div className="destination-select__area-name">{area.name}</div>
        <div className="destination-select__area-children">{resortsHtml}</div>
        { isExpandable && (
          <Icon
            path={iconPath}
            className="destination-select__area-expand-icon"
            size="xxs"
          />
        )}
      </button>
    );
  }

  renderAreaAndResorts(country) {
    const areaResorts = country.areaResorts || country.destinationAirports;
    const areaResortsHtml = [];

    for (let i = 0; i < areaResorts.length; i++) {
      if (areaResorts[i].isArea) {
        areaResortsHtml.push(this.renderArea(areaResorts[i], i));
      } else {
        areaResortsHtml.push(this.renderResort(areaResorts[i], i));
      }
    }

    return areaResortsHtml;
  }

  renderCountry(country, index) {
    const { selectedDestination } = this.props;
    const { expandedCountries } = this.state;

    const areaResorts = country.areaResorts || country.destinationAirports;

    let countryClasses = ClassNames({
      'destination-select__country': true,
      'destination-select__country--selected': country.itemId == selectedDestination.countryId,
    });

    if ((areaResorts[0].itemId == -1 && country.itemId == -1) || (country.name == areaResorts[0].name && areaResorts[0].resorts == 0)) {
      return (
        <button
          className={countryClasses}
          onClick={() => this.selectDestination(areaResorts[0])}
          key={index}
          type="button"
        >
          <div className="destination-select__country-name">{country.name}</div>
        </button>
      );
    }

    const isExpanded = expandedCountries.indexOf(country.itemId) > -1;

    countryClasses = ClassNames({
      'destination-select__country': true,
      'destination-select__country--expandable': true,
      'destination-select__country--expanded': isExpanded,
    });

    const iconPath = isExpanded ? UI_CHEVRON_UP : UI_CHEVRON_DOWN;

    return (
      <button
        className={countryClasses}
        key={index}
        onClick={() => this.toggleExpandedCountry(country.itemId)}
        type="button"
      >
        <div className="destination-select__country-name">{country.name}</div>
        <div className="destination-select__country-children">{this.renderAreaAndResorts(country)}</div>
        <Icon
          path={iconPath}
          className="destination-select__country-expand-icon"
          size="xxs"
        />
      </button>
    );
  }

  renderDestinationColumns() {
    const { countries } = this.props;
    const minDestinationsPerCol = Math.floor(countries.length / NUMBER_OF_COLUMNS);
    let rest = countries.length - minDestinationsPerCol * NUMBER_OF_COLUMNS;
    const columns = [];
    let counter = 0;

    for (let i = 0; i < NUMBER_OF_COLUMNS; i++) {
      let numberOfItems = minDestinationsPerCol;
      const countryList = countries.slice();

      if (rest > 0) {
        numberOfItems++;
        rest--;
      }

      const items = countryList.slice(counter, counter + numberOfItems);

      columns.push(
        <div className="destination-select__column" key={i}>
          {items.map((country, index) => this.renderCountry(country, index))}
        </div>
      );

      counter += numberOfItems;
    }

    return columns;
  }

  render() {
    const { searchDestinations, searchResult, labels } = this.props;

    return (
      <div className="destination-select">
        <DestinationSearch
          onTextSearch={searchTerm => searchDestinations(searchTerm)}
          searchResult={searchResult}
          selectDestination={destination => this.selectDestinationFreeText(destination)}
          labels={labels}
        />
        {this.renderDestinationColumns()}
      </div>
    );
  }
}

DestinationSelect.propTypes = {
  countries: PropTypes.array.isRequired,
  selectedDestination: PropTypes.object.isRequired,
  setDestination: PropTypes.func.isRequired,
  searchDestinations: PropTypes.func.isRequired,
  searchResult: PropTypes.object,
  labels: PropTypes.object.isRequired,
};
