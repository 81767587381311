import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormField from '../formField/FormField';
import FormFieldLoader from '../formField/FormFieldLoader';
import { getPaxFormText } from '../../utils/pax/helper';

const PaxField = (props) => {
  const {
    className,
    labels,
    isLoading,
    isActive,
    isOtherFieldActive,
    toggleOverlay,
    roomDistribution,
    selectedCabinClass,
    children,
  } = props;

  const { paxInputLabel, flightClass } = labels;

  const labelAndFlightclass =
    selectedCabinClass && flightClass
      ? `${paxInputLabel} / ${flightClass}`
      : paxInputLabel;

  const classes = classNames(
    'bs-form__select',
    'bs-form__pax-select',
    className,
  );

  if (isLoading) {
    return (
      <div className={classes}>
        <FormFieldLoader label={paxInputLabel} />
      </div>
    );
  }

  return (
    <div className={classes}>
      <FormField
        value={getPaxFormText(roomDistribution, labels, selectedCabinClass)}
        label={labelAndFlightclass}
        click={() => toggleOverlay()}
        isActive={isActive}
        isOtherFieldActive={isOtherFieldActive}
      />
      {isActive && children}
    </div>
  );
};

PaxField.propTypes = {
  labels: PropTypes.shape({
    paxInputLabel: PropTypes.string,
    flightClass: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isOtherFieldActive: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  roomDistribution: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCabinClass: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PaxField.defaultProps = {
  selectedCabinClass: null,
}

export default PaxField;
