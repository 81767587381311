export const ActionTypes = {
  INIT: 'INIT',
  INIT_SUCCESS: 'INIT_SUCCESS',
  CHANGE_SELECTED_PRODUCT: 'CHANGE_SELECTED_PRODUCT',
  BS_INITIALIZED: 'BS_INITIALIZED',
  SEARCH_INIT: 'SEARCH_INIT',
  SEARCH_DONE: 'SEARCH_DONE',

  PKG_INIT_SECTION: 'PKG_INIT_SECTION',
  PKG_INIT_SECTION_SUCCESS: 'PKG_INIT_SECTION_SUCCESS',
  PKG_CHANGE_DEPARTURE_AIRPORT: 'PKG_CHANGE_DEPARTURE_AIRPORT',
  PKG_SEARCH_DEPARTURE_AIRPORT_SUCCESS: 'PKG_SEARCH_DEPARTURE_AIRPORT_SUCCESS',
  PKG_SEARCH_DESTINATIONS_SUCCESS: 'PKG_SEARCH_DESTINATIONS_SUCCESS',
  PKG_GET_COUNTRIES: 'PKG_GET_COUNTRIES',
  PKG_GET_COUNTRIES_SUCCESS: 'PKG_GET_COUNTRIES_SUCCESS',
  PKG_CHANGE_DESTINATION: 'PKG_CHANGE_DESTINATION',
  PKG_GET_DURATIONS: 'PKG_GET_DURATIONS',
  PKG_GET_DURATIONS_SUCCESS: 'PKG_GET_DURATIONS_SUCCESS',
  PKG_CHANGE_DURATION: 'PKG_CHANGE_DURATION',
  PKG_GET_DEPARTURE_DATES: 'PKG_GET_DEPARTURE_DATES',
  PKG_GET_DEPARTURE_DATES_SUCCESS: 'PKG_GET_DEPARTURE_DATES_SUCCESS',
  PKG_CHANGE_DEPARTURE_DATE: 'PKG_CHANGE_DEPARTURE_DATE',
  PKG_CHANGE_DATES: 'PKG_CHANGE_DATES',
  PKG_CHANGE_ROOM_DISTRIBUTION: 'PKG_CHANGE_ROOM_DISTRIBUTION',
  PKG_TOGGLE_EVERYONE_IN_SAME_ROOM_CHECKED: 'PKG_TOGGLE_EVERYONE_IN_SAME_ROOM_CHECKED',
  PKG_CHANGE_DEPARTURE_MONTHS: 'PKG_CHANGE_DEPARTURE_MONTHS',
  PKG_SET_SHOW_PRICE_CALENDAR: 'PKG_SET_SHOW_PRICE_CALENDAR',
  PKG_SET_SHOW_DIRECT_FLIGHTS: 'PKG_SET_SHOW_DIRECT_FLIGHTS',
  PKG_GET_PRICE_CALENDAR_DATA_SUCCESS: 'PKG_GET_PRICE_CALENDAR_DATA_SUCCESS',
  PKG_SET_PREVIOUS_DURATION: 'PKG_SET_PREVIOUS_DURATION',
  PKG_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS: 'PKG_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS',
  PKG_SET_CABINCLASS: 'PKG_SET_CABINCLASS',
  PKG_SET_SEARCH_DIRECT_ONLY: 'PKG_SET_SEARCH_DIRECT_ONLY',
  PKG_SET_SEARCH_LUGGAGE_INCLUDED: 'PKG_SET_SEARCH_LUGGAGE_INCLUDED',
  PKG_SET_SHOW_DATE_LAYER: 'PKG_SET_SHOW_DATE_LAYER',

  CITY_INIT_SECTION: 'CITY_INIT_SECTION',
  CITY_INIT_SECTION_SUCCESS: 'CITY_INIT_SECTION_SUCCESS',
  CITY_CHANGE_DEPARTURE_AIRPORT: 'CITY_CHANGE_DEPARTURE_AIRPORT',
  CITY_SEARCH_DEPARTURE_AIRPORT_SUCCESS: 'CITY_SEARCH_DEPARTURE_AIRPORT_SUCCESS',
  CITY_GET_RESORTS: 'CITY_GET_RESORTS',
  CITY_GET_RESORTS_SUCCESS: 'CITY_GET_RESORTS_SUCCESS',
  CITY_CHANGE_RESORT: 'CITY_CHANGE_RESORT',
  CITY_SEARCH_DESTINATIONS_SUCCESS: 'CITY_SEARCH_DESTINATIONS_SUCCESS',
  CITY_GET_DURATIONS: 'CITY_GET_DURATIONS',
  CITY_GET_DURATIONS_SUCCESS: 'CITY_GET_DURATIONS_SUCCESS',
  CITY_CHANGE_DURATION: 'CITY_CHANGE_DURATION',
  CITY_GET_DEPARTURE_DATES: 'CITY_GET_DEPARTURE_DATES',
  CITY_GET_DEPARTURE_DATES_SUCCESS: 'CITY_GET_DEPARTURE_DATES_SUCCESS',
  CITY_CHANGE_DEPARTURE_DATE: 'CITY_CHANGE_DEPARTURE_DATE',
  CITY_CHANGE_DATES: 'CITY_CHANGE_DATES',
  CITY_CHANGE_ROOM_DISTRIBUTION: 'CITY_CHANGE_ROOM_DISTRIBUTION',
  CITY_TOGGLE_EVERYONE_IN_SAME_ROOM_CHECKED: 'CITY_TOGGLE_EVERYONE_IN_SAME_ROOM_CHECKED',
  CITY_CHANGE_DEPARTURE_MONTHS: 'CITY_CHANGE_DEPARTURE_MONTHS',
  CITY_SET_SHOW_PRICE_CALENDAR: 'CITY_SET_SHOW_PRICE_CALENDAR',
  CITY_SET_SHOW_DIRECT_FLIGHTS: 'CITY_SET_SHOW_DIRECT_FLIGHTS',
  CITY_GET_PRICE_CALENDAR_DATA_SUCCESS: 'CITY_GET_PRICE_CALENDAR_DATA_SUCCESS',
  CITY_SET_PREVIOUS_DURATION: 'CITY_SET_PREVIOUS_DURATION',
  CITY_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS: 'CITY_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS',
  CITY_SET_CABINCLASS: 'CITY_SET_CABINCLASS',
  CITY_SET_SEARCH_DIRECT_ONLY: 'CITY_SET_SEARCH_DIRECT_ONLY',
  CITY_SET_SEARCH_LUGGAGE_INCLUDED: 'CITY_SET_SEARCH_LUGGAGE_INCLUDED',

  CRUISE_INIT_SECTION: 'CRUISE_INIT_SECTION',
  CRUISE_INIT_SECTION_SUCCESS: 'CRUISE_INIT_SECTION_SUCCESS',
  CRUISE_CHANGE_DEPARTURE: 'CRUISE_CHANGE_DEPARTURE',
  CRUISE_CHANGE_AREA: 'CRUISE_CHANGE_AREA',
  CRUISE_GET_AREAS: 'CRUISE_GET_AREAS',
  CRUISE_GET_AREAS_SUCCESS: 'CRUISE_GET_AREAS_SUCCESS',
  CRUISE_GET_DEPARTURE_DATES: 'CRUISE_GET_DEPARTURE_DATES',
  CRUISE_GET_DEPARTURE_DATES_SUCCESS: 'CRUISE_GET_DEPARTURE_DATES_SUCCESS',
  CRUISE_CHANGE_DEPARTURE_DATES: 'CRUISE_CHANGE_DEPARTURE_DATES',
  CRUISE_CHANGE_ROOM_DISTRIBUTION: 'CRUISE_CHANGE_ROOM_DISTRIBUTION',
  CRUISE_CHANGE_CHILD_AGE: 'CRUISE_CHANGE_CHILD_AGE',
  CRUISE_ADD_CHILD_AGE: 'CRUISE_ADD_CHILD_AGE',

  FLIGHT_INIT_SECTION: 'FLIGHT_INIT_SECTION',
  FLIGHT_INIT_SECTION_SUCCESS: 'FLIGHT_INIT_SECTION_SUCCESS',
  FLIGHT_CHANGE_DEPARTURE_AIRPORT: 'FLIGHT_CHANGE_DEPARTURE_AIRPORT',
  FLIGHT_SEARCH_DESTINATIONS_SUCCESS: 'FLIGHT_SEARCH_DESTINATIONS_SUCCESS',
  FLIGHT_GET_COUNTRIES: 'FLIGHT_GET_COUNTRIES',
  FLIGHT_GET_COUNTRIES_SUCCESS: 'FLIGHT_GET_COUNTRIES_SUCCESS',
  FLIGHT_CHANGE_DESTINATION: 'FLIGHT_CHANGE_DESTINATION',
  FLIGHT_GET_DURATIONS: 'FLIGHT_GET_DURATIONS',
  FLIGHT_GET_DURATIONS_SUCCESS: 'FLIGHT_GET_DURATIONS_SUCCESS',
  FLIGHT_CHANGE_DURATION: 'FLIGHT_CHANGE_DURATION',
  FLIGHT_GET_DEPARTURE_DATES: 'FLIGHT_GET_DEPARTURE_DATES',
  FLIGHT_GET_DEPARTURE_DATES_SUCCESS: 'FLIGHT_GET_DEPARTURE_DATES_SUCCESS',
  FLIGHT_CHANGE_DEPARTURE_DATE: 'FLIGHT_CHANGE_DEPARTURE_DATE',
  FLIGHT_CHANGE_DATES: 'FLIGHT_CHANGE_DATES',
  FLIGHT_CHANGE_PASSENGER_COMBINATION: 'FLIGHT_CHANGE_PASSENGER_COMBINATION',
  FLIGHT_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS: 'FLIGHT_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS',
  FLIGHT_SET_CABINCLASS: 'FLIGHT_SET_CABINCLASS',
  FLIGHT_SET_SEARCH_DIRECT_ONLY: 'FLIGHT_SET_SEARCH_DIRECT_ONLY',
  FLIGHT_SET_SEARCH_LUGGAGE_INCLUDED: 'FLIGHT_SET_SEARCH_LUGGAGE_INCLUDED',

  HOTEL_INIT_SECTION: 'HOTEL_INIT_SECTION',
  HOTEL_INIT_SECTION_SUCCESS: 'HOTEL_INIT_SECTION_SUCCESS',
  HOTEL_SEARCH_DESTINATIONS_SUCCESS: 'HOTEL_SEARCH_DESTINATIONS_SUCCESS',
  HOTEL_GET_COUNTRIES: 'HOTEL_GET_COUNTRIES',
  HOTEL_GET_COUNTRIES_SUCCESS: 'HOTEL_GET_COUNTRIES_SUCCESS',
  HOTEL_CHANGE_RESORT: 'HOTEL_CHANGE_RESORT',
  HOTEL_CHANGE_DATES: 'HOTEL_CHANGE_DATES',
  HOTEL_CHANGE_ROOM_DISTRIBUTION: 'HOTEL_CHANGE_ROOM_DISTRIBUTION',
};
