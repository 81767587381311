import bookingTypes from '../../constants/bookingTypes';

export const getTotalNumberOfAdults = (roomDistribution) => {
  return [...roomDistribution].reduce((a, b) => {
    return a + b.numberOfAdults;
  }, 0);
};

export const getTotalNumberOfChildren = (roomDistribution) => {
  return [...roomDistribution].reduce((a, b) => {
    return a + b.numberOfChildren;
  }, 0);
};

export const getTotalNumberOfPax = (roomDistribution) => {
  return getTotalNumberOfAdults(roomDistribution) + getTotalNumberOfChildren(roomDistribution);
};

export const getNumberOfPaxAvailable = (roomDistribution, maxNumberOfPax) => {
  return maxNumberOfPax - getTotalNumberOfPax(roomDistribution);
};

export const getPaxFormText = (roomDistribution, labels, selectedCabinClass) => {
  let numberOfAdults = 0;
  let numberOfChildren = 0;
  let adultText = '';
  let childrenText = '';
  let cabinClassText = '';

  const { adultPluralText, adultSingularText, childPluralText, childSingularText } = labels;

  roomDistribution.forEach((room) => {
    numberOfAdults += room.numberOfAdults;
    numberOfChildren += room.numberOfChildren;
  });

  adultText = `${numberOfAdults} ${numberOfAdults > 1 ? adultPluralText : adultSingularText}`;

  if (numberOfChildren > 0) {
    childrenText += ', ';
    childrenText += `${numberOfChildren} ${numberOfChildren > 1 ? childPluralText : childSingularText}`;
  }

  if (selectedCabinClass) {
    cabinClassText += ', ';
    cabinClassText += labels[`class${selectedCabinClass}`] || selectedCabinClass;
  }

  return adultText + childrenText + cabinClassText;
};

export const getRoomDistributionString = (roomDistribution, bookingType) => {
  const isCharter = bookingType === bookingTypes.charter;
  let roomAges = isCharter ? '|' : '';
  const roomDivider = isCharter ? '' : '|';
  for (let i = 0; i < roomDistribution.length; i += 1) {
    const adults = roomDistribution[i].numberOfAdults;
    const children = roomDistribution[i].numberOfChildren;
    if (adults > 0 || children > 0) {
      roomAges += roomDivider;
      for (let y = 0; y < adults; y += 1) {
        roomAges += '42,';
      }
      for (let y = 0; y < children; y += 1) {
        roomAges += `${roomDistribution[i].childAges[y]},`;
      }
    }
  }
  roomAges = roomAges.slice(0, roomAges.length - 1);
  return roomAges;
};

export const parseDpRoomDistributionString = (roomDistributionString = '|42,42') => {
  /*
    Each room is separated by a |. Split out each room using the pipe to
    get an array of rooms, filter out the ones that are empty (first one since
    the string has a leading pipe).

    E.g '|42,42,6,7|42,42,8' gives us an array of two rooms ['42,42,6,7', '42,42,8']
  */
    
 const adult = '42'; // an adult is always 42 - Douglas Adams much?
 const roomDivider = '|';
 const rooms = roomDistributionString.split(roomDivider).filter(room => room !== '');
  
  // Now map the rooms and return an object for each one containing the number of
  // adults, children and the ages of the children
  const roomDistribution = rooms.map(room => {
    // Each number represents a person, adult or child. Split them on comma and filter out potential empties caused by trailing comma
    const persons = room.split(',').filter(person => person !== '');
    const adults = persons.filter(person => person === adult);
    const children = persons.filter(person => person !== adult);

    return {
      numberOfAdults: adults.length,
      numberOfChildren: children.length,
      childAges: children,
    };
  })
  
  return roomDistribution;
};

export const getCruiseRoomDistributionString = (roomDistribution) => {
  let agesString = '';
  const [firstRoom] = roomDistribution;
  const { numberOfAdults, numberOfChildren } = firstRoom;

  for (let y = 0; y < numberOfAdults; y += 1) {
    agesString += '42,';
  }

  for (let index = 0; index < numberOfChildren; index += 1) {
    agesString += `${firstRoom.childAges[index]},`;
  }

  agesString = agesString.slice(0, agesString.length - 1);

  return agesString;
};

export const getAgesString = (roomDistribution) => {
  let ages = '';
  for (let i = 0; i < roomDistribution.length; i += 1) {
    const adults = roomDistribution[i].numberOfAdults;
    const children = roomDistribution[i].numberOfChildren;
    if (adults > 0 || children > 0) {
      for (let y = 0; y < adults; y += 1) {
        ages += '42,';
      }
      for (let y = 0; y < children; y += 1) {
        ages += `${roomDistribution[i].childAges[y]},`;
      }
    }
  }
  ages = ages.slice(0, ages.length - 1);
  return ages;
};
