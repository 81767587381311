import PropTypes from 'prop-types';
import { LoadBlock } from '@bwoty-web/styleguide';

const FormFieldLoader = ({ label }) => {
  return (
    <div className="form-field form-field--loader">
      <div className="form-field__label">{label}</div>
      <div className="form-field__load-block">
        <LoadBlock />
      </div>
    </div>
  );
};

FormFieldLoader.propTypes = {
  label: PropTypes.string,
};

FormFieldLoader.defaultProps = {
  label: '',
};

export default FormFieldLoader;
