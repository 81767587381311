import moment from 'moment';
import { getQueryKeyValue } from '../utils/url/queryHelper';
import QueryKeys from '../utils/url/queryKeys';
import { getCruiseRoomDistributionString } from '../utils/pax/helper';
import { getApiUrls, getSearchUrlAndStoreSearch } from './api';
import { fetchJson } from '../utils/fetch';


export const getInitState = async (preSelectedSettings) => {
  const { BOOKING_START } = QueryKeys;
  const resortId = getQueryKeyValue(BOOKING_START.resortId);
  const areaId = getQueryKeyValue(BOOKING_START.areaId);
  const areaResortId = resortId !== '' && resortId !== '0' ? resortId : areaId;
  const roomDistribution = getQueryKeyValue(BOOKING_START.roomDistribution);
  const roomAges = typeof roomDistribution === 'string' && roomDistribution.startsWith('|') ? roomDistribution.substr(1) : roomDistribution;

  const apiUrls = await getApiUrls();
  const url = apiUrls.cruiseInitStateUrl
    .replace('{departureId}', getQueryKeyValue(BOOKING_START.departureId) || preSelectedSettings.departureId || '-')
    .replace('{areaResortId}', areaResortId || preSelectedSettings.resortId || '-')
    .replace('{departureDate}', getQueryKeyValue(BOOKING_START.departureDate) || '-')
    .replace('{roomAges}', roomAges || '-');


  const initState = await fetchJson(url);

  return initState;
};

export const getAreas = async (departureId) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.cruiseAreasUrl
    .replace('{departureId}', departureId);

  const areas = await fetchJson(url);

  return areas;
};

export const getDepartureDates = async (departureId, areaId) => {
  const apiUrls = await getApiUrls();
  const url = apiUrls.cruiseDepartureDatesUrl
    .replace('{departureId}', departureId)
    .replace('{itemId}', areaId);

  const departureDates = await fetchJson(url);

  return departureDates;
};

export const getSearchUrl = async (cruiseSection) => {
  const {
    siteSettings,
    roomDistribution,
    selectedDepartureDate,
    selectedDeparture,
    selectedArea,
  } = cruiseSection;

  const { siteId, apiUrl } = siteSettings;

  const roomAges = getCruiseRoomDistributionString(roomDistribution);

  const depDate = moment(selectedDepartureDate).format('YYYYMMDD');
  const depId = selectedDeparture.itemId;
  const areaId = selectedArea.itemId;

  const sectionData = {
    depId,
    destId: '0',
    ctryId: '0',
    areaId,
    resId: '0',
    dur: '0',
    depDate,
    retDate: '0',
    depMonths: '0',
    roomAges,
    sameRoom: 'true',
    searchCharter: 'false',
    categoryId: '101',
    categoryFilterId: '0',
  };

  const searchUrls = await getSearchUrlAndStoreSearch(sectionData);

  const url = searchUrls.cruise
    .replace('{depId}', depId)
    .replace('{areaId}', areaId)
    .replace('{depDate}', depDate)
    .replace('{roomAges}', roomAges);

  return url;
};
