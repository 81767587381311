import { ActionTypes } from '../constants/actionTypes/bookingstart';

const cruiseReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CRUISE_INIT_SECTION:
      return Object.assign({}, state, {
        loading: true,
      });

    case ActionTypes.CRUISE_INIT_SECTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        departureList: action.data.departures,
        cruiseAreas: action.data.areas,
        departureDates: action.data.departureDates,
        rooms: action.data.rooms,
        selectedDeparture: action.data.selectedDeparture,
        selectedArea: action.data.selectedArea,
        selectedDepartureDate: action.data.selectedDepartureDate,
        selectedNumberOfRooms: action.data.selectedNumberOfRooms,
        selectedChildAges: action.data.selectedChildAges,
        type: action.data.type,
        searchHistory: action.data.searchHistory,
        sectionName: 'cruise',
      });

    case ActionTypes.CRUISE_CHANGE_DEPARTURE:
      return Object.assign({}, state, {
        selectedDeparture: action.departure,
      });

    case ActionTypes.CRUISE_GET_AREAS_SUCCESS:
      return Object.assign({}, state, {
        cruiseAreas: action.areas,
        selectedArea: action.selectedArea,
      });

    case ActionTypes.CRUISE_GET_DEPARTURE_DATES_SUCCESS:
      return Object.assign({}, state, {
        departureDates: action.departureDates,
        selectedDepartureDate: action.selectedDepartureDate,
      });

    case ActionTypes.CRUISE_CHANGE_AREA:
      return Object.assign({}, state, {
        selectedArea: action.area,
      });

    case ActionTypes.CRUISE_CHANGE_DEPARTURE_DATES:
      return Object.assign({}, state, {
        selectedDepartureDate: action.departureDate,
      });

    case ActionTypes.CRUISE_CHANGE_ROOM_DISTRIBUTION:
      return Object.assign({}, state, {
        rooms: action.roomDistribution,
      });

    case ActionTypes.CRUISE_CHANGE_CHILD_AGE:
      return Object.assign({}, state, {
        selectedChildAges: [...state.selectedChildAges.slice(0, action.childIndex), action.age, ...state.selectedChildAges.slice(action.childIndex + 1)],
      });

    case ActionTypes.CRUISE_ADD_CHILD_AGE:
      const childAgesToAdd = [];
      while (childAgesToAdd.length < action.nOfChildAges) {
        childAgesToAdd.push(-1);
      }

      let selectedChildAges = state.selectedChildAges;
      if (selectedChildAges == null) {
        selectedChildAges = [];
      }
      return Object.assign({}, state, {
        selectedChildAges: [...selectedChildAges, ...childAgesToAdd],
      });

    default:
      return state;
  }
};

export default cruiseReducer;
