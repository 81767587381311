/* eslint-disable no-use-before-define */
import moment from 'moment';
import store from '../../stores/bookingstart';
import * as cityService from '../../../services/city';
import bookingTypes from '../../../constants/bookingTypes';
import { ActionTypes } from '../../constants/actionTypes/bookingstart';
import {
  getSelectedDuration,
  getSelectedDepartureDate,
  getPriceCalendarDataKey,
  getSelectedOffer,
} from '../../../utils/actionHelper';

const getExtendedSearchOptions = (selectedValues) => {
  return async (dispatch) => {
    const {
      selectedDepartureAirport,
      selectedResort,
    } = selectedValues;

    // Get extended search options
    const { siteSettings } = store.getState().bookingstart;
    const { siteId, baseUrl } = siteSettings;
    const departureAirportId = selectedDepartureAirport.itemId;
    const destinationId = selectedResort.itemId;

    const options = await cityService.getExtendedSearchOptions(
      baseUrl,
      siteId,
      departureAirportId,
      destinationId,
      destinationId,
    );

    dispatch({
      type: ActionTypes.CITY_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS,
      options,
    });
  }
};

const getCalendarPrices = (selectedValues) => {
  return async (dispatch) => {
    const {
      selectedDepartureAirport,
      selectedResort,
      selectedDuration,
      showPriceCalendar,
      showDirectFlights,
    } = selectedValues;

    const shouldFetchPrices = showPriceCalendar && selectedDuration.hasCalendar;
    if (!shouldFetchPrices) {
      return;
    }

    // Get calendar prices
    const { siteSettings } = store.getState().bookingstart;
    const { priceCalendarData } = store.getState().city;

    const departureAirportId = selectedDepartureAirport.itemId;
    const destinationId = selectedResort.itemId;
    const priceCalendarKey = getPriceCalendarDataKey(departureAirportId, destinationId, 0, selectedDuration, showDirectFlights);
    // Fetch prices if not fetched previously.
    if (!priceCalendarData[priceCalendarKey]) {
      const minDepDate = moment().add(siteSettings.datePicker.dpBookDaysAhead, 'days');
      const maxDepDate = moment().add(siteSettings.datePicker.dpMaxDaysToReturn, 'days');
      let dateToFetch = minDepDate;
      const tempPriceCalendarData = {};
      while (!dateToFetch.isAfter(maxDepDate)) {
        // eslint-disable-next-line no-await-in-loop
        const data = await cityService.getPriceCalendar(
          departureAirportId,
          destinationId,
          dateToFetch.format('YYYY-MM-DD'),
          selectedDuration.defaultDuration + 1,
          showDirectFlights,
        );
        if (data && data.calendars && data.calendars.length > 0) {
          data.calendars.forEach((calendarMonth) => {
            const dataMonth = calendarMonth.month;
            tempPriceCalendarData[dataMonth] = calendarMonth;
          });
          if (data.calendars.length < 4) {
            // We should get calendar for 4 months in each request.
            // If less than 4 months is returned, then no more calendar data exists and we should stop fetching new data.
            break;
          }
          const lastFetchedCalenderMonth = moment(data.calendars[data.calendars.length - 1].month);
          if (!lastFetchedCalenderMonth.isAfter(dateToFetch)) {
            break;
          }
          dateToFetch = lastFetchedCalenderMonth.add(1, 'M');
        } else {
          break;
        }
      }

      priceCalendarData[priceCalendarKey] = [];
      Object.keys(tempPriceCalendarData).forEach((key) => {
        priceCalendarData[priceCalendarKey].push(tempPriceCalendarData[key]);
      });
      dispatch({
        type: ActionTypes.CITY_GET_PRICE_CALENDAR_DATA_SUCCESS,
        priceCalendarData,
      });
    }
  }
};

export const initCitySection = (preSelectedSettings) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_INIT_SECTION,
    });

    const data = await cityService.getInitState(preSelectedSettings);

    dispatch({
      type: ActionTypes.CITY_INIT_SECTION_SUCCESS,
      data,
      showPriceCalendar: preSelectedSettings.showPriceCalendar,
      showDirectFlights: preSelectedSettings.showDirectFlights,
    });

    if (preSelectedSettings.showPriceCalendar) {
      dispatch(setShowPriceCalendar(true));

      if (data.selectedDuration.flexibleDuration) {
        dispatch(setPreviousDuration(data.selectedDuration));
        dispatch(changeDuration(data.options[0], false));
      }
    }

    dispatch(getExtendedSearchOptions({
      selectedDepartureAirport: data.selectedDeparture,
      selectedResort: data.selectedResort,
    }));
  };
};

export const getDepartureDates = (selectedValues) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_GET_DEPARTURE_DATES,
    });

    const { siteSettings } = store.getState().bookingstart;
    const { systemDateFormat, dpBookDaysAhead } = siteSettings.datePicker;

    const {
      selectedDepartureAirport,
      selectedResort,
      selectedDuration,
      selectedDepartureDate,
      selectedReturnDate,
    } = selectedValues;

    const { defaultDuration, flexibleDuration, bookingType } = selectedDuration;

    if (bookingType === bookingTypes.charter) {
      const departureDates = await cityService.getCharterDepartureDates(
        selectedDepartureAirport.itemId,
        selectedResort.itemId,
        defaultDuration,
      );

      const durationLength = defaultDuration !== -1 ? defaultDuration : 3;

      const departureDate = getSelectedDepartureDate(departureDates, selectedDepartureDate);
      const returnDate = moment(departureDate).add(durationLength, 'days').format(systemDateFormat);

      dispatch({
        type: ActionTypes.CITY_GET_DEPARTURE_DATES_SUCCESS,
        departureDates,
        selectedDepartureDate: departureDate,
        selectedReturnDate: returnDate,
      });
    } else {
      const departureDates = await cityService.getIndependentDepartureDates(
        selectedDepartureAirport.itemId,
        selectedResort.itemId,
        defaultDuration,
        flexibleDuration,
      );

      const firstPossibleBookingDayDp = moment().add(dpBookDaysAhead, 'days');

      let departureDate = moment(selectedDepartureDate);
      let returnDate = moment(selectedReturnDate);

      if (flexibleDuration) {
        if (departureDate.isBefore(firstPossibleBookingDayDp)) {
          departureDate = moment(firstPossibleBookingDayDp).format(systemDateFormat);
          returnDate = moment(departureDate).add(defaultDuration, 'days').format(systemDateFormat);
        }
      } else {
        departureDate = getSelectedDepartureDate(departureDates, selectedDepartureDate);
        returnDate = moment(selectedDepartureDate).add(defaultDuration, 'days').format(systemDateFormat);
      }

      dispatch({
        type: ActionTypes.CITY_GET_DEPARTURE_DATES_SUCCESS,
        departureDates,
        selectedDepartureDate: departureDate,
        selectedReturnDate: returnDate,
      });
    }
  };
};

export const setPreviousDuration = (previousDuration) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_SET_PREVIOUS_DURATION,
      previousDuration,
    });
  }
}

export const getDurations = (selectedValues) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_GET_DURATIONS,
    });

    const { selectedDepartureAirport, selectedResort, selectedDuration } = selectedValues;

    const durationsData = await cityService.getDurations(selectedDepartureAirport.itemId, selectedResort.itemId);
    const durations = durationsData ? durationsData.durations : [];
    const duration = getSelectedDuration(durations, selectedDuration);
    const options = durationsData ? durationsData.options : [];

    dispatch({
      type: ActionTypes.CITY_GET_DURATIONS_SUCCESS,
      durations,
      selectedDuration: duration,
      options,
    });

    dispatch(setPreviousDuration(null));

    const newSelectedValues = {
      ...selectedValues,
      selectedDuration: duration,
    };

    dispatch(getDepartureDates(newSelectedValues));
    dispatch(getCalendarPrices(newSelectedValues));
    dispatch(getExtendedSearchOptions(selectedValues));
  };
};

export const getResorts = (selectedValues) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_GET_RESORTS,
    });

    const { selectedDepartureAirport, selectedResort } = selectedValues;

    const resorts = await cityService.getResorts(selectedDepartureAirport.itemId);

    let resort = resorts[0];
    for (let i = 0; i < resorts.length; i += 1) {
      if (resorts[i].itemId === selectedResort.itemId) {
        resort = resorts[i];
        break;
      }
    }

    dispatch({
      type: ActionTypes.CITY_GET_RESORTS_SUCCESS,
      resorts,
      selectedResort: resort,
    });

    const newSelectedValues = {
      ...selectedValues,
      selectedResort: resort,
    };

    dispatch(getDurations(newSelectedValues));
  };
};

export const changeDepartureAirport = (airport) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_CHANGE_DEPARTURE_AIRPORT,
      selectedDepartureAirport: airport,
    });

    const selectedValues = {
      ...store.getState().city,
      selectedDepartureAirport: airport,
    };

    dispatch(getResorts(selectedValues));
    dispatch(getCalendarPrices(selectedValues));
  };
};

export const changeResort = (resort) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_CHANGE_RESORT,
      selectedResort: resort,
    });

    const selectedValues = {
      ...store.getState().city,
      selectedResort: resort,
    };

    dispatch(getDurations(selectedValues));
    dispatch(getCalendarPrices(selectedValues));
  };
};

export const searchDestinations = (departureId, searchTerm) => {
  return async (dispatch) => {
    const searchResults = await cityService.searchDestinations(departureId, searchTerm);
    dispatch({
      type: ActionTypes.CITY_SEARCH_DESTINATIONS_SUCCESS,
      searchResults,
    });
  };
};

export const changeDuration = (duration, clearPreviousDuration = true) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_CHANGE_DURATION,
      selectedDuration: duration,
    });

    if (clearPreviousDuration) {
      dispatch(setPreviousDuration(null));
    }

    const selectedValues = {
      ...store.getState().city,
      selectedDuration: duration,
    };

    const priceCalendarDurations =
    selectedValues?.priceCalendarDurationOptions?.map(
      (option) => option.defaultDuration,
    );

    if (!priceCalendarDurations.includes(duration.defaultDuration)) {
      dispatch(setShowPriceCalendar(false));
    }

    dispatch(getDepartureDates(selectedValues));
    dispatch(getCalendarPrices(selectedValues));
  };
};

export const changeDepartureDate = (date) => {
  return (dispatch) => {
    const { siteSettings } = store.getState().bookingstart;
    const { systemDateFormat } = siteSettings.datePicker;
    const {
      selectedDuration,
      selectedReturnDate,
      selectedDepartureAirport,
      selectedResort,
      showDirectFlights,
      showPriceCalendar,
      priceCalendarData,
    } = store.getState().city;
    const { defaultDuration, bookingType } = selectedDuration;

    const durationLength = defaultDuration !== -1 ? defaultDuration : 3;
    let returnDate = selectedReturnDate;

    if (bookingType === bookingTypes.charter) {
      returnDate = moment(date).add((durationLength - 1), 'days').format(systemDateFormat);
    } else {
      returnDate = moment(date).add(durationLength, 'days').format(systemDateFormat);
    }

    let selectedOffer = null;

    if (showPriceCalendar) {
      const key = getPriceCalendarDataKey(
        selectedDepartureAirport.itemId,
        selectedResort.itemId,
        0,
        selectedDuration,
        showDirectFlights,
      );
      
      selectedOffer = getSelectedOffer(key, priceCalendarData, date);
    }

    dispatch({
      type: ActionTypes.CITY_CHANGE_DEPARTURE_DATE,
      selectedDepartureDate: date,
      selectedReturnDate: returnDate,
      selectedOffer,
    });
  };
};

export const changeDates = (departureDate, returnDate) => {
  return {
    type: ActionTypes.CITY_CHANGE_DATES,
    selectedDepartureDate: departureDate,
    selectedReturnDate: returnDate,
  };
};

export const changeRoomDistribution = (roomDistribution, selectedNumberOfRooms, isSameRoom) => {
  return {
    type: ActionTypes.CITY_CHANGE_ROOM_DISTRIBUTION,
    roomDistribution,
    selectedNumberOfRooms,
    isSameRoom,
  };
};

export const toggleEveryoneInSameRoomChecked = (isEveryoneInSameRoomChecked) => {
  return {
    type: ActionTypes.CITY_TOGGLE_EVERYONE_IN_SAME_ROOM_CHECKED,
    isEveryoneInSameRoomChecked: !isEveryoneInSameRoomChecked,
  };
};

export const changeDepartureMonths = (months) => {
  const { departureDates, selectedDuration: duration } = store.getState().city;
  const { siteSettings } = store.getState().bookingstart;
  const { systemDateFormat } = siteSettings.datePicker;
  const [firstMonth] = months;

  const departureDate = getSelectedDepartureDate(departureDates, firstMonth);
  const returnDate = moment(departureDate)
    .add(duration.defaultDuration, 'days')
    .format(systemDateFormat);

  return {
    type: ActionTypes.CITY_CHANGE_DEPARTURE_MONTHS,
    departureMonths: months,
    selectedDepartureDate: departureDate,
    selectedReturnDate: returnDate,
  };
};

export const setShowPriceCalendar = (showPriceCalendar) => {
  return async (dispatch) => {
    dispatch ({
      type: ActionTypes.CITY_SET_SHOW_PRICE_CALENDAR,
      showPriceCalendar,
    });

    const selectedValues = {
      ...store.getState().city,
      showPriceCalendar,
    }

    dispatch(getCalendarPrices(selectedValues));
  }
};

export const setShowDirectFlights = (showDirectFlights) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_SET_SHOW_DIRECT_FLIGHTS,
      showDirectFlights,
    });

    const selectedValues = {
      ...store.getState().city,
      showDirectFlights,
    };

    dispatch(getCalendarPrices(selectedValues));
  };
};

export const setSearchDirectOnly = (searchDirectOnly) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_SET_SEARCH_DIRECT_ONLY,
      searchDirectOnly,
    });
  };
};

export const setCabinClass = (cabinclass) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_SET_CABINCLASS,
      cabinclass,
    })
  }
}


export const setSearchLuggageIncluded = (searchLuggageIncluded) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CITY_SET_SEARCH_LUGGAGE_INCLUDED,
      searchLuggageIncluded,
    });
  };
};