import { combineReducers } from 'redux';
import bookingstart from './bookingstart';
import packageReducer from './package';
import cityReducer from './city';
import cruiseReducer from './cruise';
import flightReducer from './flight';

const RootReducer = combineReducers({
  bookingstart,
  package: packageReducer,
  city: cityReducer,
  cruise: cruiseReducer,
  flight: flightReducer,
});

export default RootReducer;
